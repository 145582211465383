import React from 'react'
import Map from './Map'
// import getBookingObj from '../../Commens/Commens'

const Address = (props) => {

  return (
    <Map error={(error) => props.error(error)} />
  )
}

export default Address