import React from "react";
import "./coming.scss";
import Layout from "../Component/Layout";

const Disclaimer = () => {
  return (
    <Layout>
      <div className="page mt-24">
        <div className="max-w-[1200px] m-auto text-4xl leading-[35px]">
          <p className="text-center text-6xl font-bold mb-20">
            DISCLAIMER: CLEANING SERVICE AREA AND REFUND POLICY
          </p>
          <p className="mb-10">
            Please read the following disclaimer carefully before availing of
            our cleaning services. By engaging with our services, you
            acknowledge and accept the terms outlined below:
          </p>
          <p className="mb-10">
            <span className="font-bold">Service Area:</span> Our cleaning
            services are currently available in Dubai, United Arab Emirates
            (UAE). Limited to the following locations;{" "}
          </p>
          <ol className="list-disc pl-10 mb-12">
            <li className="mb-4">JVC</li>
            <li className="mb-4">Barsha Heights</li>
            <li className="mb-4">Palm Jumeirah</li>
            <li className="mb-4">Dubai Marina</li>
            <li className="mb-4">The Greens </li>
            <li className="mb-4">Arabian Ranches</li>
            <li className="mb-4">JLT</li>
            <li className="mb-4">Motor City </li>
            <li className="mb-4">Sports City</li>
            <li className="mb-4">Dubai Hills</li>
            <li className="mb-4">JVT</li>
            <li className="mb-4">JBR</li>
          </ol>
          <p className="mb-10">
            We operate within the designated areas of Dubai and provide our
            cleaning services to residential and commercial properties within
            this region. Kindly note that any requests for service outside our
            designated service area may not be accommodated at this time.
          </p>
          <p className="mb-10">
            <span className="font-bold">Refund Policy:</span> We strive to
            deliver the highest level of satisfaction to our valued customers.
            In the event that you are dissatisfied with our cleaning service, we
            offer a refund policy. Please note the following details regarding
            refunds.{" "}
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Disclaimer;
