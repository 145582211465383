import { Home, About, Contact, Category, ServiceDetails, Signup, Error, Privacy, ComingSoon, Tip, Profile, ThankYou } from './Pages'
import { Route, BrowserRouter, Routes, HashRouter } from "react-router-dom";
import Disclaimer from './Pages/Disclaimer';

function App() {
  return (
    <HashRouter>
      <Routes>
        {localStorage.token ? (
          <>
            <Route path="/Tip" element={<Tip />} />
            <Route path="/Profile" element={<Profile />} />
          </>
        ) : (
          <>
            <Route path="/Tip" element={<Signup />} />
            <Route path="/Signup" element={<Signup />} />
          </>
        )}

        <Route path="*" element={<Error />} />
        <Route path="/ComingSoon" element={<ComingSoon />} />
        <Route path="/disclaimer" element={<Disclaimer   />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/ThankYou" element={<ThankYou />} />
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Category/:id" element={<Category />} />
        <Route path="/ServiceDetails/:id" element={<ServiceDetails />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
