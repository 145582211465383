import Layout from "../Component/Layout";
import Button from "../Component/UI/Button/Button";
import { RiArrowDropLeftLine, RiMapPinLine, RiArrowDropRightLine } from "react-icons/ri";
import { BsArrowRepeat, BsCalendar2Plus, BsCashCoin } from "react-icons/bs";
import { MdCleaningServices } from "react-icons/md";
import { BiErrorCircle } from "react-icons/bi";

import Address from "../Component/Booking/Address";
import Details from "../Component/Booking/Details";
import DateTime from "../Component/Booking/DateTime";
import Frequency from "../Component/Booking/Frequency";
import { useEffect, useState } from "react";
import Phone from "../Component/Auth/Phone";
import Error from "../Component/UI/Alert/Error";
import { t } from "i18next";
import axios from "axios";
import { configs, getBookingObj, setBookingObj, url } from "../Commens/Commens";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

let x = "";

const startComponent = () => {
  return <Frequency />;
};

const firstComponent = () => {
  return (
    <Address
      error={(error) => {
        document.getElementById("errorDiv").style.display = "flex";
        document.getElementById("errorMessge").innerHTML = error;
        if (!error) {
          document.getElementById("errorDiv").style.display = "none";
        }
      }}
    />
  );
};
const secondComponent = () => {
  return <Details />;
};
const thirdComponent = () => {
  return <DateTime />;
};
const finalComponent = () => {
  return (
    <Phone
      error={(error) => {
        document.getElementById("errorDiv").style.display = "flex";
        document.getElementById("errorMessge").innerHTML = error;
        if (!error) {
          document.getElementById("errorDiv").style.display = "none";
        }
      }}
    />
  );
};

const ServiceDetails = () => {
  const { id } = useParams();
  const [ferqency, setFerqency] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessge, setErrorMessage] = useState([]);

  const [steps, setSteps] = useState([
    {
      key: "startStep",
      label: <BsArrowRepeat className="text-5xl text-white" />,
      isDone: true,
      component: startComponent,
    },
    {
      key: "firstStep",
      label: <RiMapPinLine className="text-5xl text-white" />,
      isDone: true,
      component: firstComponent,
    },
    {
      key: "secondStep",
      label: <MdCleaningServices className="text-5xl text-white" />,
      isDone: false,
      component: secondComponent,
    },
    {
      key: "thirdStep",
      label: <BsCalendar2Plus className="text-5xl text-white" />,
      isDone: false,
      component: thirdComponent,
    },
    {
      key: "finalStep",
      label: <BsCashCoin className="text-5xl text-white" />,
      isDone: false,
      component: finalComponent,
    },
  ]);

  const [activeStep, setActiveStep] = useState(steps[0]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const sendGetRequest = async () => {
      try {
        const resp = await axios.get(url + `categories/${id}`, configs);
        if (resp.status === 200) {
          const data = resp.data.data;
          console.log(data.fequency_options);
          setFerqency(data.fequency_options);
          if (data.fequency_options.length == 0) {
            setActiveStep(steps[1]);
            console.log(activeStep.key);
          } else if (data.fequency_options.length > 0) {
            setActiveStep(steps[0]);
            console.log(activeStep.key);
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
  }, [id]);

  const handleNext = () => {
    if (steps[steps.length - 5].key === activeStep.key) {
      if (!localStorage.bookingObj) {
        setErrorMessage(<>{t("Please choose the answer !!")}</>);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 2000);
      } else {
        const index = steps.findIndex((x) => x.key === activeStep.key);
        setSteps((prevStep) =>
          prevStep.map((x) => {
            if (x.key === activeStep.key) x.isDone = true;
            return x;
          })
        );
        setActiveStep(steps[index + 1]);
      }
      return;
    }

    if (steps[steps.length - 4].key === activeStep.key) {
      let newObject = window.localStorage.getItem("bookingObj");
      const locationVal = JSON.parse(newObject).location_value;
      if (!locationVal) {
        setErrorMessage(<>{t("Please choose select your location !!")}</>);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 2000);
        if (locationVal.address2 == "") {
          setErrorMessage(<>{t("Please choose select your location !!")}</>);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 2000);
        }
      } else {
        const index = steps.findIndex((x) => x.key === activeStep.key);
        setSteps((prevStep) =>
          prevStep.map((x) => {
            if (x.key === activeStep.key) x.isDone = true;
            return x;
          })
        );
        setActiveStep(steps[index + 1]);
      }

      return;
    }

    if (steps[steps.length - 3].key === activeStep.key) {
      let newObject = window.localStorage.getItem("bookingObj");
      const servicesVal = JSON.parse(newObject).services;
      if (servicesVal) {
        if (servicesVal.length > 0) {
          const index = steps.findIndex((x) => x.key === activeStep.key);
          setSteps((prevStep) =>
            prevStep.map((x) => {
              if (x.key === activeStep.key) x.isDone = true;
              return x;
            })
          );
          setActiveStep(steps[index + 1]);
        } else {
          setErrorMessage(<>{t("Please choose select a service !!")}</>);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 2000);
        }
      } else {
        setErrorMessage(<>{t("Please choose select a service !!")}</>);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 2000);
      }
      return;
    }

    if (steps[steps.length - 2].key === activeStep.key) {
      if (localStorage.token) {
        document.getElementById("NextButton").setAttribute("disabled", "disabled");

        const old = getBookingObj();

        const paymentObj = {};
        old.payment = paymentObj;
        old.token = localStorage.token;
        setBookingObj(old);
        const data = old;
        // const configsPost = configs
        // configsPost.data = data

        axios({
          method: "post", //you can set what request you want to be
          url: url + `book`,
          data: data,
          headers: {
            Accept: "application/json",
            lang: localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "en",
            Authorization: `Bearer ${localStorage.getItem("token")?.replace(/['"]+/g, "")}`,
          },
        })
          .then((response) => {
            navigate(`/ThankYou?paymentLink=${response.data.payment_link}`, { replace: true });
          })
          .catch((error) => {
            document.getElementById("NextButton").setAttribute("disabled", "");

            setError(error.response.data.error);
            setTimeout(() => {
              setError(false);
            }, 5000);
          });
      } else {
        document.getElementById("NextButton").setAttribute("disabled", "disabled");

        let newObject = window.localStorage.getItem("bookingObj");
        const dateVal = JSON.parse(newObject).date;
        if (!dateVal) {
          setErrorMessage(<> {t("Please select a Date and Time !!")} </>);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 2000);
        }
        if (dateVal && dateVal.length < 10) {
          setErrorMessage(<> {t("Please select a Date and Time !!")} </>);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 2000);
        } else {
          const index = steps.findIndex((x) => x.key === activeStep.key);
          setSteps((prevStep) =>
            prevStep.map((x) => {
              if (x.key === activeStep.key) x.isDone = true;
              return x;
            })
          );
          setActiveStep(steps[index + 1]);
        }

        return;
      }
    }

    if (steps[steps.length - 1].key === activeStep.key) {
      alert("You have completed all steps please confirm your booking.");
      return;
    }
  };

  const handleBack = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0 || activeStep == steps[1]) return;

    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
      })
    );
    setActiveStep(steps[index - 1]);
  };

  return (
    <Layout>
      <div id="ServiceDetails" className="mt-12 lg:mt-48">
        <div className="container mx-auto">
          <div className="row flex items-start justify-between flex-wrap">
            <div className="w-full">
              <div className="steps">
                <ul className="nav flex items-center justify-between mb-8 text-2xl relative overflow-hidden">
                  {steps.map((step, i) => {
                    return (
                      <li
                        key={i}
                        className={`w-28 h-28 flex items-center justify-center text-center p-4 leading-10 my-2 lg:mx-4 fill-subColor ${
                          activeStep.key === step.key
                            ? "active bg-mainColor text-subColor rounded-full  fill-white "
                            : "bg-gray rounded-full"
                        } ${step.isDone ? "done bg-mainColor" : ""}`}
                      >
                        <div className="font-bold ">
                          <span className="font-normal flex justify-center">{step.label}</span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-8/12 px-4 lg:pr-8 mt-6">
              <div className="box">
                <div className="step-component">{activeStep.component()}</div>
              </div>
            </div>
            <div className="w-full lg:w-4/12">
              <div className="Cart p-4 px-8 mt-12 border-2 border-mainColor rounded-xl mx-4">
                <ul className="text-2xl relative overflow-hidden">
                  <div className="loading hidden absolute left-0 top-0 w-full h-full z-50 opacity-90 items-center justify-center bg-white ">
                    <div className="Loading h-full w-full  mx-auto flex items-center justify-center">
                      <div className="loadingio-spinner-spinner-b5p7g8eokig">
                        <div className="ldio-dlp895cf7od">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul
                    className="font-bold my-4 w-full h-full absolute text-red leading-10 text-center bg-white  hidden items-center justify-center"
                    id="error"
                  ></ul>
                  <li className="font-bold my-4">{t("Cart Details")}</li>
                  <li id="cartDetails" className="text-2xl my-4 leading-10">
                    ــــــــــــــــ{" "}
                  </li>
                  <hr className="my-8 border border-mainColor" />
                  <li className="font-bold mb-4">{t("PRICE DETAILS")}</li>
                  <li className="text-2xl my-4 flex items-center justify-between">
                    <span>{t("Price")}</span>
                    <span>
                      {" "}
                      <b id="cartPrice">00.00</b> <b id="cartCurrency">{t("AED")}</b>{" "}
                    </span>{" "}
                  </li>
                  <li className="text-2xl my-4 flex items-center justify-between">
                    <span>{t("VAT")}</span>
                    <span>
                      <b id="cartVat">00.00</b> <b id="cartCurrency">{t("AED")}</b>
                    </span>{" "}
                  </li>
                  <li className="font-bold text-2xl my-4 flex items-center justify-between">
                    <span>{t("Total")}</span>
                    <span>
                      <b id="cartTotal">00.00</b> <b id="cartCurrency">{t("AED")}</b>
                    </span>{" "}
                  </li>
                </ul>
              </div>

              <div
                id="errorDiv"
                className="hidden justify-start items-start max-w-full relative overflow-hidden bg-white rounded-xl shadow-md my-8 mx-4"
              >
                <div className="flex items-center justify-center  bg-errorColor text-white p-4 mt-4 rounded-xl">
                  <BiErrorCircle className="text-5xl" />
                </div>
                <div className="px-4 py-2 -mx-3 flex items-center text-2xl">
                  <div className="mx-3 py-2">
                    <span className="font-semibold mb-4">{t("Error")}</span>
                    <p className=" text-gray-600 text-2xl text-errorColor" id="errorMessge">
                      {" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center px-4 gap-12">
                <button
                  className="mainBtn blueBtn mt-8 py-4 w-1/2  flex justify-center items-center opacity-70"
                  value="Back"
                  onClick={handleBack}
                  disabled={steps[0].key === activeStep.key}
                >
                  {t("Back")} <RiArrowDropLeftLine className="text-3xl" />
                </button>
                <button
                  id="NextButton"
                  className="mainBtn blueBtn mt-8 py-4 w-1/2 flex justify-center items-center"
                  value={steps[steps.length - 1].key !== activeStep.key ? "Next" : "Submit"}
                  onClick={handleNext}
                >
                  {t("Next")} <RiArrowDropRightLine className="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ServiceDetails;
