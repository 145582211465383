import React from "react";
import { Link } from "react-router-dom";
import logo from "../../img/h_logo.png";
import {
  AiOutlineCloseCircle,
  AiOutlineWhatsApp,
  AiOutlineInstagram,
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { t } from "i18next";

const Sidebar = (props) => {
  const handleCloseSideBar = () => {
    props.onChangeSideBar(false);
  };
  return (
    <>
      <div
        onClick={handleCloseSideBar}
        className="overlay fixed bg-white top-0 rtl:right-0 ltr:left-0 w-full shadow-lg h-screen z-50 flex items-center justify-center flex-wrap opacity-60"
      ></div>
      <sidebar
        className="fixed bg-mainColor top-0 rtl:right-0 ltr:left-0 w-10/12 shadow-lg h-screen z-50 flex items-center justify-start flex-wrap"
        style={{ zIndex: "9999999999" }}
      >
        <button
          onClick={handleCloseSideBar}
          className="absolute top-4 rtl:left-4 ltr:right-4 text-5xl text-white"
        >
          <AiOutlineCloseCircle />
        </button>
        <img
          src={logo}
          alt=""
          className="w-10/12 ltr:mr-auto  rtl:ml-auto px-4"
        />
        <ul className="rtl:pr-4 ltr:pl-4">
          <li className="text-3xl leading-loose text-white mb-4">
            <Link to="/"> {t("Home ")} </Link>
          </li>
          <li className="text-3xl leading-loose text-white mb-4">
            <Link
              onClick={() => {
                handleCloseSideBar();
              }}
              to={
                localStorage.token
                  ? "/Category/-1"
                  : `${window.location.pathname}?login=true`
              }
            >
              {" "}
              {t("Services ")}{" "}
            </Link>
          </li>
          <li className="text-3xl leading-loose text-white mb-4">
            <Link to="/About"> {t("About us ")} </Link>
          </li>
          <li className="text-3xl leading-loose text-white mb-4">
            <Link to="/contact"> {t("Contact Us")} </Link>
          </li>
          <li className="text-3xl leading-loose text-white mb-4">
            <Link to="/Privacy"> {t("Privacy  ")} </Link>
          </li>
        </ul>
        <ul className="w-full flex items-center justify-start px-6">
          <li className="rtl:pl-4 ltr:pr-4 text-5xl text-white">
            <a href="http://www.facebook.com">
              <AiOutlineInstagram />
            </a>{" "}
          </li>
          <li className="pr-4 text-5xl text-white">
            <a href="http://www.facebook.com">
              <AiOutlineWhatsApp />
            </a>
          </li>
          <li className="pr-4 text-5xl text-white">
            <a href="http://www.facebook.com">
              <FaTiktok />
            </a>
          </li>
          <li className="pr-4 text-5xl text-white">
            <a href="http://www.facebook.com">
              <FiFacebook />
            </a>
          </li>
        </ul>
      </sidebar>
    </>
  );
};

export default Sidebar;
