import { useEffect, useState } from "react";
import Button from "../../UI/Button/Button";
import { FaHandsWash } from "react-icons/fa";
import { RiFeedbackLine } from "react-icons/ri";
import Avatar from "../../../img/avatar1.svg";
import Avatar2 from "../../../img/avatar2.svg";
import Avatar3 from "../../../img/avatar3.svg";
import Avatar4 from "../../../img/avatar4.svg";
import "./index.scss";
import { configs, url } from "../../../Commens/Commens";
import axios from "axios";
import Service from "../../Service/Service";
import { Link } from "react-router-dom";
import { t } from "i18next";

const BestServices = () => {
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [service, setServices] = useState({});

  useEffect(() => {
    localStorage.removeItem('phone');
    axios
      .get(url + "categories?parent=-1", configs)
      .then((response) => {
        if (response.status === 404) {
          setIsNotFound(true);
          return;
        }
        if (response.status === 400) {
          setIsNotValid(true);
          return;
        }
        const data = response.data.data;
        setServices(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  if (isNotFound) {
    return <p>NotFound</p>;
  }
  if (isNotValid) {
    return <p className="error">Sorry! The reservation ID is invalid.</p>;
  }
  if (isLoading) {
    return <p className="error">Load.</p>;
  }

  return (
    <div className="mt-24 lg:mt-48">
      {/* <div id="BestServices" className="mt-24">
        <div className="container mx-auto">
          <div className="row flex items-center justify-center flex-wrap w-full">
            <div className="w-full md:w-6/12 xl:w-1/4 ltr:lg:pr-12 rtl:lg:pl-12">
              <h3 className="text-4xl mb-6 font-medium text-subColor text-center ltr:md:text-left rtl:md:text-right">
                {t('Our Best Services')}
              </h3>
              <p className="text-2xl leading-loose mb-8 text-center ltr:md:text-left rtl:md:text-right">
                {t('Your trusted home service in UAE starting from AED 25 per hour')}
              </p>
              <div className="flex justify-center md:justify-start items-center mb-8 ">
                <Link to='/Category/-1' className="mainBtn blueBtn mt-4  py-4 px-6">
                  <FaHandsWash className="mx-4" />
                  {t('Explore Our Services')}
                </Link>
              </div>
            </div>
            <div className="w-full md:w-6/12 xl:w-3/4">
              <div className="row flex justify-center items-center flex-wrap ">
                {service.map((item, index) => (
                  <Service
                    id={item.id}
                    key={index}
                    image={item.icon}
                    name={item.name}
                    description={item.description}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <h3 className="text-4xl xl:text-[3.5rem] mb-24 text-center text-subColor font-bold text-subColor">
        {" "}
        {t("Rate our services")}
      </h3>
      <div id="LittleTip" className="avatar">
        <div className="container mx-auto">
          <div className="row flex items-center justify-center flex-wrap">
            <div className="w-full relative">
              <div className=" relative h-auto lg:h-96 py-32 lg:py-16 lg:p-16">
                <img
                  src={Avatar2}
                  alt=""
                  className="absolute -bottom-24 right-0 lg:right-1/4  w-48 lg:w-72 h-auto"
                />
                <img
                  src={Avatar}
                  alt=""
                  className="absolute -top-24 left-0 w-48 lg:w-72 h-auto"
                />
                <div className="text-center w-full md:w-8/12 xl:w-1/2 mx-auto">
                  <p className="text-2xl leading-loose mb-8 ">
                    {" "}
                    {t(
                      "We will always be committed to taking care of our hardworking professionals in any way we can. To help us deliver better services, Please give us a feedback."
                    )}
                  </p>
                  <div className="flex justify-center items-center mb-8">
                    <Link
                      to="/contact"
                      className="mainBtn blueBtn mt-4  py-4 px-6"
                    >
                      <RiFeedbackLine className=" text-3xl mx-4 md:text-white" />
                      {t("Give feedback")}
                    </Link>
                  </div>
                </div>
                <img
                  src={Avatar3}
                  alt=""
                  className="absolute -top-24 right-0 lg:right-16 w-48 lg:w-72 h-auto"
                />
                <img
                  src={Avatar4}
                  alt=""
                  className="absolute -bottom-24 left-0 lg:left-1/4 w-48 lg:w-72 h-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestServices;
