import ScreenNav from './ScreenNav'
import Footer from './Footer'
import MobileNav from './MobileNav'

function index(props) {
  return (
    <div className='min-h-screen'>
    {window.innerWidth < 768 ?<MobileNav /> : <ScreenNav /> } 
      {props.children}
      <Footer />
    </div>
  )
}

export default index  