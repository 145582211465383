import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { configs, url } from '../Commens/Commens'
import Layout from '../Component/Layout'
import Error from '../Component/UI/Alert/Error'
import Success from '../Component/UI/Alert/Success'
import { GiPayMoney} from 'react-icons/gi'

const Tip = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState()
  const [addAmount, setAddAmount] = useState()
  const [cardNo, setCardNo] = useState()
  const [expDateMonth, setExpDateMonth] = useState()
  const [expDateYear, setExpDateYear] = useState()
  const [name, setName] = useState()
  const [Cvv, setCvv] = useState()
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorMessge, setErrorMessage] = useState()

  useEffect(() => {
    localStorage.removeItem('bookingObj');
  }, [])

  const handleSubmit = () => {
    if (!localStorage.token) {
      navigate('/Signup')
    }
    // const data = `"card_no": ${cardNo}, "exp_date_month": ${expDateMonth}, "exp_date_year": ${expDateYear}, "name": ${name}, "cvv": ${Cvv}`
    const data = {}
    data.card_no = cardNo
    data.exp_date_month = expDateMonth
    data.exp_date_year = expDateYear
    data.name = name
    data.cvv = Cvv
    const obj = {
      amount: parseInt(amount),
      payment: data
    }
    console.log(obj)

    axios.post(url + `tips/send`, obj, configs).then(response => {
      console.log(response.data)
      setAmount('')
      setAddAmount('')
      setCardNo('')
      setExpDateMonth('')
      setExpDateYear('')
      setName('')
      setCvv('')
      setSuccess(true)
      setTimeout(()=>{
        navigate('/ThankYou')
        setSuccess(false)
      },3000)
    }).catch(error => {
      setErrorMessage(error.message)
      console.log(error)
            setError(true)
            setTimeout(() => {
                      setError(false) 

            }, 2000);
    })
  }

  return (
    <Layout>
      {
                error && <div
                        className="w-screen h-screen fixed top-0 left-0 bg-redLight z-50 flex items-center justify-center">
                        <Error>
                            {errorMessge}
                        </Error>
                    </div>
            }
            {
                success && <div
                        className="w-screen h-screen fixed top-0 left-0 bg-greenLight z-50 flex items-center justify-center">
                        <Success />
                    </div>
            }
      <div className=" flex items-center justify-start lg:justify-center py-24">
        <div className="container mx-auto">
          <div className="row flex items-center justify-start lg:justify-center">
            <div className="w-full lg:w-2/3 px-4 mt-16">
              <div className="border-2 border-mainColor w-full rounded-lg flex items-center justify-center flex-wrap p-4 md:p-8">
                <h3 className='w-full flex font-bold text-5xl text-center my-8 justify-center'>Tip it forward</h3>
                <div className='flex justify-center flex-wrap w-full'>
                  {
                    amount ?
                      <div className="flex flex-col justify-center relative overflow-hidden items-center text-3xl p-4 py-8 transition-all duration-300 border-2 border-mainColor hover:bg-mainColor hover:text-white w-48 h-44 m-4 rounded-lg">
                        <p className='text-5xl font-black'>{amount}</p>
                        <p className='my-2 font-bold'>AED</p>
                      </div>
                      :
                      <>
                        <div className={`flex flex-col justify-center relative overflow-hidden items-center text-3xl p-4 py-8 transition-all duration-300 border-2 border-mainColor hover:bg-mainColor hover:text-white w-44 h-44 m-4 rounded-lg ${amount == '10' & 'bg-mainColor'}`}>
                          <input className='absolute w-full h-full opacity-0 cursor-pointer ' type="radio" onChange={(e) => { setAddAmount(false); setAmount(e.target.value) }} name="tip" id="" value='10' readOnly />
                          <p className='text-5xl font-black'>10</p>
                          <p className='my-2 font-bold'>AED</p>
                        </div>
                        <div className={`flex flex-col justify-center relative overflow-hidden items-center text-3xl p-4 py-8 transition-all duration-300 border-2 border-mainColor hover:bg-mainColor hover:text-white w-44 h-44 m-4 rounded-lg ${amount == '25' & 'bg-mainColor'}`}>
                          <input className='absolute w-full h-full opacity-0 cursor-pointer ' type="radio" onChange={(e) => { setAddAmount(false); setAmount(e.target.value) }} name="tip" id="" value='25' readOnly />
                          <p className='text-5xl font-black'>25</p>
                          <p className='my-2 font-bold'>AED</p>
                        </div>
                        <div className={`flex flex-col justify-center relative overflow-hidden items-center text-3xl p-4 py-8 transition-all duration-300 border-2 border-mainColor hover:bg-mainColor hover:text-white w-44 h-44 m-4 rounded-lg ${amount == '50' & 'bg-mainColor'}`}>
                          <input className='absolute w-full h-full opacity-0 cursor-pointer ' type="radio" onChange={(e) => { setAddAmount(false); setAmount(e.target.value) }} name="tip" id="" value='50' readOnly />
                          <p className='text-5xl font-black'>50</p>
                          <p className='my-2 font-bold'>AED</p>
                        </div>
                      </>
                  }
                  {
                    amount ?
                      <>
                        <div className="flex flex-col justify-center items-center text-3xl border-2  text-subColor transition-all duration-300 bg-yellow border-yellow hover:bg-darkBlue hover:border-darkBlue hover:text-white  w-44 h-44 m-4 rounded-lg relative overflow-hidden cursor-pointer">
                          <p className='leading-10 p-4 py-8 font-bold text-3xl text-center'>You will pay</p>
                        </div>
                        <div onClick={() => setAmount('')} className="flex flex-col justify-center items-center text-3xl border-2  text-white transition-all duration-300	 bg-redLight hover:bg-redLight hover:border-redLight w-44 h-44 m-4 rounded-lg relative overflow-hidden cursor-pointer">
                          <p className='leading-10 p-4 py-8 font-bold text-4xl text-center'>Cancel</p>
                        </div>
                      </>
                      :
                      <div onClick={() => setAddAmount(true)} className="flex flex-col justify-center items-center border-2  text-subColor  transition-all duration-300	 bg-yellow border-yellow hover:bg-darkBlue hover:border-darkBlue hover:text-white w-44 h-44 m-4 rounded-lg relative overflow-hidden cursor-pointer">
                        <p className='leading-10 p-4 py-8 font-bold text-3xl text-center'>Add You Amount</p>
                      </div>
                  }

                </div>
                {addAmount && <div className="AddAmount justify-center flex w-full p-6">
                  <div className="form-group w-full md:w-1/2 mt-6">
                    <label htmlFor="" className='font-bold text-2xl'>Add your Amount</label>
                    <input onChange={(e) => setAmount(e.target.value)} value={amount} maxLength='6' className="block w-full px-4 py-4 mt-2 text-gray-700  border-2 border-mainColor text-3xl rounded-lg " type="text" placeholder='Add your Amount' />
                  </div>
                </div>
                }


                <div className='flex items-center justify-center flex-wrap lg:flex-nowrap lg:gap-4 w-full mt-16'>
                  <div className="form-group w-full md:w-1/4 mt-6">
                    <label htmlFor="" className='font-bold text-2xl'>Card Number</label>
                    <input onChange={(e) => setCardNo(e.target.value)} className="block w-full px-4 py-4 mt-2 text-gray-700  border-2 border-mainColor text-3xl rounded-lg " type="text" placeholder='Card Number' />
                  </div>
                  <div className="form-group w-full md:w-1/4 mt-6">
                    <label htmlFor="" className='font-bold text-2xl'>CVV</label>
                    <input onChange={(e) => setCvv(e.target.value)} className="block w-full px-4 py-4 mt-2 text-gray-700  border-2 border-mainColor text-3xl rounded-lg " type="number" placeholder='CVV' />
                  </div>
                  <div className="form-group w-full md:w-1/4 mt-6">
                    <label htmlFor="" className='font-bold text-2xl'>Exp. Date</label>
                    <div className="flex gap-2">
                      <input onChange={(e) => setExpDateMonth(e.target.value)} maxLength='2' className="block w-full px-2 py-4 mt-2 text-gray-700  border-2 border-mainColor text-3xl rounded-lg " type="text" placeholder='Month' />
                      <input onChange={(e) => setExpDateYear(e.target.value)} maxLength='4' className="block w-full px-2 py-4 mt-2 text-gray-700  border-2 border-mainColor text-3xl rounded-lg " type="text" placeholder='Year' />
                    </div>
                  </div>
                  <div className="form-group w-full md:w-1/4 mt-6">
                    <label htmlFor="" className='font-bold text-2xl'>Name on card</label>
                    <input onChange={(e) => setName(e.target.value)} maxLength='20' className="block w-full px-4 py-4 mt-2 text-gray-700  border-2 border-mainColor text-3xl rounded-lg " type="text" placeholder='Name on card' />
                  </div>
                </div>
                <button  onClick={handleSubmit}  className="mainBtn blueBtn mt-16 py-2 px-8">
                  <GiPayMoney className=" text-3xl mx-4" />
                  Pay 
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  )
}

export default Tip