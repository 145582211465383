import React from 'react'
import img from '../img/v_logo.png'
import './coming.scss'

const ComingSoon = () => {


          return (
                    <div className="page">
                              <div className="countdown-col col">
                                        <h3 className='font-bold'> Dangi Clean  </h3>
                                        <h3  className='text-5xl text-white font-bold my-6'> Under Renovation  </h3>
                                        <h3> Coming Soon </h3>
                              </div>
                              <div className="photo">
                                        <img src={img} alt="" className="logo" />
                                        {/* <h3>Contact Us : </h3> */}
                                        {/* <div className="contact"> */}
                                        {/* <p> <img src="img/cell-phone.png" alt="" /> */}

                                        <a href="mailto:info@dangiclean.com" className=' text-white text-3xl my-4'>  info@dangiclean.com  </a>
                                        {/* <p> <img src="img/whatsapp.png" alt="" /> */}
                                        {/* <a href="whatsapp://send?abid=+971 52 996 2679&text=Welcom at Brain Click!"> +971 52 996 2679 </p> */}
                                        {/* <p> <img src="img/phone.png" alt="" />  <a href="tel:+971 52 996 2679"> +971 4 568 7471  </a></p> */}

                                        {/* </div>
                                                  </div> */}
                              </div>
                    </div>
          )
}

export default ComingSoon