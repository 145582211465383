import React, { useState } from "react";
// import { LoadScript } from "@react-google-maps/api";
import Button from "../UI/Button/Button";
import {
  LoadScript,
  GoogleMap,
  StandaloneSearchBox, Polygon, useGoogleMap, useLoadScript, useJsApiLoader
} from "@react-google-maps/api";
import pin from "../../img/pin.svg";
import { getBookingObj, setBookingObj } from "../../Commens/Commens";
import { useParams } from "react-router-dom";
import {
  configs,
  url,
  showLoading,
  updateCostCard,
} from "../../Commens/Commens";
import axios from "axios";
import Error from "../UI/Alert/Error";
import { useEffect } from "react";
import { t, use } from "i18next";
import ErrorAlert from "../UI/Alert/ErrorAlert";
import SuccessAlert from "../UI/Alert/SuccessAlert";
import { is } from "date-fns/locale";
// import handleLoading from '../../Pages/ServiceDetails'
const key = "AIzaSyAV8VEG1RLclapyZ92xOujbsX1lRnIksdc";
const lib = ["places"];

const Map = (props) => {
  const { id } = useParams();
  const [pickLocation, setPickLocation] = useState("");
  const [LocationValue, setLocationValue] = useState("");
  const [addressID, setAddressID] = useState("");

  const [address, setAddress] = useState("");
  const [mapref, setMapRef] = useState(null);
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [defaultLocation, setDefaultLocation] = useState({
    lat: 25.270012722064614,
    lng: 55.258894247149705,
  });
  const [allAddresses, setAllAddresses] = useState([]);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [zoomSize, setZoomSize] = useState(8);

  useEffect(() => {
    if (!localStorage.token) {
      return;
    }
    axios
      .get(url + `addresses`, configs)
      .then((response) => {
        console.log(response.data.data);
        // setSuccess(response.data.message)
        // setTimeout(() => {
        //   setSuccess('')
        // }, 5000)
      })
      .catch((error) => {
        console.log(error);
        setError(error.response);
        setTimeout(() => {
          setError("");
        }, 5000);
      });
  }, []);

  useEffect(() => {
    if (!localStorage.token) {
      return;
    }
    axios
      .get(url + `addresses`, configs)
      .then((response) => {
        setAllAddresses(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        setError(error.response);
        setTimeout(() => {
          setError("");
        }, 5000);
      });
  }, []);

  useEffect(() => {
    document.getElementById("NextButton").setAttribute("disabled", "disabled");
  }, []);

  const handleOnLoad = (map) => {
    setMapRef(map);
  };

  const displayLocation = (latitude, longitude) => {
    var request = new XMLHttpRequest();
    var method = "GET";
    var url =
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      latitude +
      "," +
      longitude +
      "&sensor=true&key=AIzaSyAV8VEG1RLclapyZ92xOujbsX1lRnIksdc";
    var async = true;

    request.open(method, url, async);
    request.onreadystatechange = function () {
      if (request.readyState == 4 && request.status == 200) {
        var data = JSON.parse(request.responseText);
        var address = data.results[0];
        setPickLocation(address.formatted_address);
        //    $('#map-location').html(address.formatted_address);
      }
    };
    request.send();
  };

  const handleCenterChanged = (e) => {
    if (mapref) {
      const newCenter = mapref.getCenter();
      setLat(newCenter.lat());
      setLng(newCenter.lng());
      displayLocation(newCenter.lat(), newCenter.lng());
      document.getElementById("searchMap").value = "";
      // console.log(newCenter)
      // if (lng === undefined || lat === undefined) {
      //           setState(false)
      // } else {
      //           setState(true)
      // }
    }
  };

  const second = [
    { lat: 25.076896, lng: 55.210193 },
    { lat: 25.028532, lng: 55.166076 },
    { lat: 25.040819, lng: 55.195774 },
    { lat: 25.035687, lng: 55.205558 },
    { lat: 25.025266, lng: 55.21088 },
    { lat: 25.034287, lng: 55.231823 },
    { lat: 25.044396, lng: 55.250877 },
    { lat: 25.061036, lng: 55.296962 },
    { lat: 25.133789, lng: 55.308635 },
    { lat: 25.140005, lng: 55.261257 }
  ]
  const latest = [
    { lat: 25.146662, lng: 55.104945 },
    { lat: 25.076093, lng: 55.108722 },
    { lat: 25.050246, lng: 55.141123 },
    { lat: 25.098291, lng: 55.185498 },
    { lat: 25.105208, lng: 55.177258 },
    { lat: 25.120214, lng: 55.160257 },
    { lat: 25.134978, lng: 55.158197 },
    { lat: 25.146167, lng: 55.134507 }
  ]
  const sportArea = [
    { lat: 25.043179, lng: 55.198583 },
    { lat: 25.050954, lng: 55.223645 },
    { lat: 25.031358, lng: 55.233945 },
    { lat: 25.018914, lng: 55.211286 }
  ]
  const [isInside, setIsInside] = useState(false);


  function updateFunObj() {
    if (lng === undefined || lat === undefined) {
      props.error("Please Select your address on Map");
      document
        .getElementById("NextButton")
        .setAttribute("disabled", "disabled");
      return;
    } else if (address.length < 6) {
      props.error("Please enter Address details, Should more than 5 letters");
      return;
    } else if (isInside === false) {
      props.error("Please enter a location within the service areas covered");
      return
    } else {
      const location = {};
      location.address1 = pickLocation;
      location.address2 = address;
      location.country = "United Arab Emirates";
      location.lat = lat;
      location.lng = lng;
      const old = getBookingObj();
      old.location_value = location;
      old.location_type = "full";
      document.getElementById("NextButton").removeAttribute("disabled");
      old.category = `${id}`;
      setBookingObj(old);
      const data = old;
      const configsPost = configs;
      configsPost.data = data;
      showLoading(true);
      axios
        .post(url + `calculate_price`, data, configsPost)
        .then((response) => {
          const rep = response.data.data;
          console.log(rep);
          updateCostCard(rep);
          setError(false);
          props.error();
          showLoading(false);
          //  props(rep)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const [searchBox, setSearchBox] = useState(null);
  const onPlacesChanged = () => {
    var lk = searchBox.getPlaces();
    setZoomSize(15);
    setDefaultLocation({
      lat: lk[0].geometry.location.lat(),
      lng: lk[0].geometry.location.lng(),
    });
    setPickLocation(lk[0].formatted_address);
    setLat(lk[0].geometry.location.lat());
    setLng(lk[0].geometry.location.lng());
  };
  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };

  useEffect(() => {
    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      }
    }

    function showPosition(position) {
      setDefaultLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    }
    getLocation();
  }, []);

  const MAP_OPTIONS = {
    scrollwheel: true,
    libraries: ["drawing, polygons"],
  };

  // const myArray = [{id: 1, name:'pipi'}, {id: 2, name:'popo'}];
  // const id = 2;

  // console.log(cariableOne[0].name);

  const handleLocation = (idLocation) => {
    console.log(idLocation);
    console.log(id);

    // const location = {};
    // location.address1 = pickLocation
    // location.address2 = address
    // location.country = "United Arab Emirates"
    // location.lat = lat
    // location.lng = lng
    const old = getBookingObj();
    old.location_value = idLocation;
    old.location_type = "address_id";
    document.getElementById("NextButton").removeAttribute("disabled");
    old.category = `${id}`;
    setBookingObj(old);
    const data = old;
    const configsPost = configs;
    configsPost.data = data;
    showLoading(true);
    axios
      .post(url + `calculate_price`, data, configsPost)
      .then((response) => {
        const rep = response.data.data;
        console.log(rep);
        updateCostCard(rep);
        setError(false);
        props.error();
        showLoading(false);
        //  props(rep)
      })
      .catch((error) => {
        console.log(error);
      });
    // setAddressID(id)
    // const variableOne = allAddresses.filter(itemInArray => itemInArray.id === id);
    // const location = {};
    // location.address1 = (variableOne[0].address1)
    // location.address2 = (variableOne[0].address2)
    // location.country = "United Arab Emirates"
    // location.lat = (variableOne[0].lat)
    // location.lng = (variableOne[0].lng)
    // setPickLocation(variableOne[0].address1)
    // setAddress(variableOne[0].address2)
    // setLat(variableOne[0].lat)
    // setLng(variableOne[0].lng)
    // displayLocation(variableOne[0].lat, variableOne[0].lng)
    // const old = getBookingObj()
    // old.location_value = location
    // old.category = `${id}`
    // setBookingObj(old)
    // const data = old
    // const configsPost = configs
    // configsPost.data = data
    // showLoading(true)
    // axios.post(url + `calculate_price`, data, configsPost).then(response => {
    //   const rep = response.data.data;
    //   updateCostCard(rep)
    //   showLoading(false)
    //   updateFunObj()
    //   document.getElementById('NextButton').removeAttribute("disabled");

    //   //  props(rep)
    // }).catch(error => {
    //   console.log(error)
    //   setError(error.response)
    //   setTimeout(() => {
    //     setError('')
    //   }, 5000)
    // })
  };

  // const filtered = asArray.filter(([key, value]) => typeof value === 'string');

  // 25.076896,55.210193,25.028532,55.166076,25.040819,55.195774,25.035687,55.205558,25.025266,55.21088,25.034287,55.231823,25.044396,55.250877,25.061036,55.296962,25.133789,55.308635,25.140005,55.261257



  // 25.146662,55.104945,25.076093,55.108722,25.050246,55.141123,25.098291,55.185498,25.105208,55.177258,25.120214,55.160257,25.134978,55.158197,25.146167,55.134507

  // function checkAddress() {
  //   var address = document.getElementById("addressInput").value;

  //   // Geocode the entered address to obtain its coordinates
  //   var geocoder = new google.maps.Geocoder();
  //   geocoder.geocode({ address: address }, function (results, status) {
  //     if (status === google.maps.GeocoderStatus.OK) {
  //       var enteredLocation = results[0].geometry.location;
  //       console.log(enteredLocation)

  //       // Check if the entered coordinates are within the geofence
  //       var isWithinGeofence = google.maps.geometry.poly.containsLocation(enteredLocation, geofencePolygon);

  //       if (isWithinGeofence) {
  //         alert("Address is within the geofence!");
  //       } else {
  //         alert("Address is outside the geofence!");
  //       }
  //     } else {
  //       alert("Geocode was not successful: " + status);
  //     }
  //   });
  // }
  useEffect(() => {
    if (lat && lng) {
      const point = new window.google.maps.LatLng(lat, lng);
      const polygon = new window.google.maps.Polygon({
        paths: latest,
      });
      const polygon2 = new window.google.maps.Polygon({
        paths: second,
      });
      const polygon3 = new window.google.maps.Polygon({
        paths: sportArea,
      });
      setIsInside(window.google.maps.geometry.poly.containsLocation(point, polygon) | window.google.maps.geometry.poly.containsLocation(point, polygon2) | window.google.maps.geometry.poly.containsLocation(point, polygon3))
      if (window.google.maps.geometry.poly.containsLocation(point, polygon) | window.google.maps.geometry.poly.containsLocation(point, polygon2)) {
        setSuccess('Location is inside the service area')
        setError('')
      } else {
        setSuccess('')
        setError('Location is outside the service area')
      }
    }
  }, [lat, lng]);

  return (
    <div className="w-full">
      {/* {error &&
          <Error> {errorMessge} </Error>
      } */}
      <div className="w-full  text-left p-4">
        {error && <ErrorAlert error={error} />}
        {success && <SuccessAlert success={success} />}
      </div>
      <div className="w-full  text-left p-4  ">
        <div id="map">
          <label
            className="text-black mb-4 block text-3xl rtl:text-right"
            htmlFor="Address"
          >
            {t("Select Your Location")}{" "}
            <span className="text-errorColor">*</span>{" "}
          </label>
          <p className="text-black mb-4 block text-3xl rtl:text-right text-errorColor">
            We offer services only in these locations: JVC, Barsha Heights, Palm
            Jumeirah, Dubai Marina, The Greens, Arabian Ranches, JLT, Motor
            City, Sports City, Dubai Hills, JVT, JBR.
          </p>
          {/* <p>{lat}, {lng}</p>
          <p>{isInside ? 'yes' : 'no'}</p> */}
          <input
            value={pickLocation}
            readOnly
            placeholder={t("addressOnMap")}
            autoComplete="true"
            id="address"
            type="text"
            className="rounded-2xl block w-full mb-4 text-mainColor bg-black text-2xl  "
          />

          <LoadScript googleMapsApiKey={key} libraries={lib}>
            <div className="relative ">
              <img
                src={pin}
                className="absolute top-1/2 left-1/2 -translate-x-1/2 w-12 z-40"
                alt=""
              />
              <GoogleMap

                center={defaultLocation}
                zoom={zoomSize}
                onLoad={handleOnLoad}
                onDragEnd={handleCenterChanged}
                options={MAP_OPTIONS}
                mapContainerStyle={{ width: "100%", height: "40rem" }}
              >
                <Polygon
                  paths={latest}
                  strokeColor="#ADFF2F"
                  strokeOpacity={0.8}
                  strokeWeight={2}
                  fillColor="#ADFF2F"
                  fillOpacity={0.25}
                />
                <Polygon
                  paths={second}
                  strokeColor="#ADFF2F"
                  strokeOpacity={0.6}
                  strokeWeight={2}
                  fillColor="#ADFF2F"
                  fillOpacity={0.25}
                />
                <Polygon
                  paths={sportArea}
                  strokeColor="#ADFF2F"
                  strokeOpacity={0.6}
                  strokeWeight={2}
                  fillColor="#ADFF2F"
                  fillOpacity={0.25}
                />
                <StandaloneSearchBox
                  onLoad={onSBLoad}
                  onPlacesChanged={onPlacesChanged}
                >
                  <input
                    id="searchMap"
                    type="text"
                    placeholder={t("Choose your location")}
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid #ccc`,
                      width: `100%`,
                      padding: `20px`,
                      paddingLeft: `5px`,
                      borderRadius: `0`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: "absolute",
                      left: "0%",
                      top: "0rem",
                    }}
                  />
                </StandaloneSearchBox>
              </GoogleMap>
            </div>
          </LoadScript>

          <label
            className="text-black mb-4 block text-3xl mt-8 rtl:text-right"
            htmlFor="Address"
          >
            {" "}
            {t("Address details")} <span className="text-errorColor">*</span>
          </label>
          <input
            placeholder={t("Address Details")}
            autoComplete="true"
            id="autoAddress"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className=" border-2 border-mainColor block w-full px-4 py-4 mt-4 text-gray-700  text-2xl rounded-xl"
          />
          <button
            className="mainBtn blueBtn mt-8 py-4 flex items-center justify-center w-full text-center"
            onClick={() => {
              if (isInside) { updateFunObj() }
              else { props.error("Please enter a Location within the covered service area"); }
            }
            }
          >
            {" "}
            {t("Confirm")}{" "}
          </button>
          {allAddresses.length > 0 && (
            <h3 className="flex items-center justify-center py-4 font-bold text-[2rem] mt-4">
              {" "}
              Or select an address
            </h3>
          )}
          {localStorage.token && (
            <div className="w-full">
              {allAddresses.length > 0 && (
                <div className=" h-96 overflow-y-auto">
                  {allAddresses.map((item, index) => (
                    <div
                      key={index}
                      className={`w-full flex items-center justify-between text-2xl border-2  my-4 p-2 py-4 rounded-lg cursor-pointer  ${item.id == addressID
                        ? "bg-mainColor text-white"
                        : "text-mainColor border-mainColor"
                        }`}
                      onClick={() => handleLocation(item.id)}
                    >
                      <p className=""> {item.address1} </p>
                      <div className="options flex items-center gap-2"> </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="w-full  text-left p-4  "></div>
    </div>
  );
};

export default Map;
