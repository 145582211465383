import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import logo from "../../img/h_logo.svg";
import flag from "../../img/uae.png";
import hsFlag from "../../img/ng.png";
import Lang from "../Lang";
import axios from "axios";
import { configs, url } from "../../Commens/Commens";
import { t } from "i18next";
import OtpInput from "react-otp-input";
import Swal from "sweetalert2";
import Hamburger from "hamburger-react";

const ScreenNav = (props) => {
  const navigate = useNavigate();
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const location = useLocation();
  console.log(location);

  const closeDropDown = () => {
    // console.log("closed");
    navigate(`${window.location.pathname}`);
    setDropdownIsOpen(false);
  };
  const [phone, setPhone] = useState();
  const [otp, setOtp] = useState(false);
  const [number1, setnumber1] = useState("");
  const [number2, setnumber2] = useState("");
  const [number3, setnumber3] = useState("");
  const [number4, setnumber4] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  // const opt = [number1, number2, number3, number4, number5]
  const [otpNumber, setOtpNumber] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var userName = localStorage.getItem("user");
  // const name = JSON.parse(userName)
  const handleSend = (event) => {
    event.preventDefault();
    if (phone.length < 8) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Something went wrong!",
        showConfirmButton: true,
        text: "Enter the correct phone number!",
        showCloseButton: true,
        timer: 3000,
      });

      return;
    }
    let data = new FormData();
    data.append(
      "phone",
      `${localStorage.i18nextLng == "ha" ? "+234" : "+971"}${phone}`
    );
    axios
      .post(url + "otp/request", data, configs)
      .then((response) => {
        setOtp(true);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleVer = (event) => {
    setButtonLoading(true);
    let data = new FormData();
    data.append(
      "phone",
      `${localStorage.i18nextLng == "ha" ? "+234" : "+971"}${phone}`
    );
    data.append("otp", otpNumber);
    axios
      .post(url + "otp/verify", data, configs)
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Success",
          showConfirmButton: true,
          text: response.data.message,
          showCloseButton: true,
          timer: 3000,
        });
        if (response.status == 206) {
          navigate("/Signup");
          localStorage.setItem("phone", phone);
        }

        localStorage.setItem("token", response.data.data.token);

        setTimeout(() => {
          if (localStorage.token) window.location.href = "/";
        }, 1000);

        setTimeout(() => {
          if (!localStorage.token) {
            navigate("/Signup");
            localStorage.setItem("phone", phone);
          }
        }, 3000);

        setOtp(false);

        // localStorage.setItem('user', JSON.stringify(response.data.data.name));
        localStorage.setItem("user", response.data.data.name);
      })
      .catch((error) => {
        console.log(error);
        setButtonLoading(false);
        setOtpNumber();
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: true,
          text: error.response.data.message,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    if (otpNumber && otpNumber.length > 5) {
      handleVer();
    }
  }, [otpNumber]);

  const Logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/");
    window.location.href = "/";
  };
  // window.addEventListener("click", function (e) {
  //   if (document.getElementById("mainNav").contains(e.target)) {
  //   } else {
  //     // navigate(window.location.pathname);
  //     setDropdownIsOpen(false);
  //   }
  // });
  const [searchParams] = useSearchParams();

  const param = [searchParams.get("login")];

  // const [params] = useParams()
  useEffect(() => {
    if (searchParams.get("login") === "true") {
      setDropdownIsOpen(true);
    }
  }, [param]);

  return (
    <nav
      className="bg-mainColor absolute top-0 left-0 z-50 w-full"
      id="mainNav"
    >
      <div className="container px-6 py-4 mx-auto relative">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex items-start justify-between">
            <div className="text-xl font-semibold text-gray-700">
              <Link
                to="/"
                className="text-2xl font-bold text-gray-800 transition-colors duration-200 transform dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"
              >
                <img src={logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="md:flex flex-col -mx-4 md:flex-row md:items-center md:mx-8 text-white hidden">
              <Link
                to="/"
                className="px-2 py-1 mx-2 my-4 mt-10 md:mb-0 md:mt-2 text-3xl font-medium "
              >
                {t("home")}
              </Link>
              {location.pathname === "/" ? (
                <a
                  href="#About"
                  className="px-2 py-1 mx-2 my-4 md:mb-0 md:mt-2 text-3xl font-medium "
                >
                  {t("about")}
                </a>
              ) : (
                <Link
                  to="/About"
                  className="px-2 py-1 mx-2 my-4 md:mb-0 md:mt-2 text-3xl font-medium "
                >
                  {t("about")}
                </Link>
              )}
              <Link
                to={
                  !localStorage.token
                    ? `${window.location.pathname}?login=true`
                    : "/Category/-1"
                }
                className="px-2 py-1 mx-2 my-4 md:mb-0 md:mt-2 text-3xl font-medium "
              >
                {t("services")}
              </Link>
              <Link
                to="/contact"
                className="px-2 py-1 mx-2 my-4 md:mb-0 md:mt-2 text-3xl font-medium "
              >
                {t("Contact Us")}
              </Link>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <Lang />

            {!localStorage.token ? (
              <>
                <div
                  onClick={() => setDropdownIsOpen(true)}
                  className="cursor-pointer flex items-center justify-center border-2 border-white p-2 px-4 rounded-3xl"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.653"
                    height="29.653"
                    viewBox="0 0 29.653 29.653"
                  >
                    <path
                      id="icons8_male_user_1"
                      d="M17.827,3A14.827,14.827,0,1,0,32.653,17.827,14.827,14.827,0,0,0,17.827,3ZM9.178,26.65c1.442-4.324,5.766-2.637,6.487-4.979V20.109a4.123,4.123,0,0,1-1.349-2.3c-.256-.022-.656-.276-.775-1.278a.948.948,0,0,1,.341-.935,4.968,4.968,0,0,1-.381-1.729c0-2.56,1.269-4.685,4.326-4.685a2.275,2.275,0,0,1,2.162,1.17c1.475,0,2.162,1.617,2.162,3.514a4.4,4.4,0,0,1-.381,1.729.945.945,0,0,1,.342.935c-.119,1-.519,1.255-.775,1.278a4.116,4.116,0,0,1-1.349,2.3v1.562c.72,2.344,5.045.656,6.487,4.98a12.354,12.354,0,0,1-17.3,0Z"
                      transform="translate(-3 -3)"
                      fill="#fff"
                    />
                  </svg>

                  <span className="text-white font-bold mx-2 text-3xl">
                    {" "}
                    {t("Login")}{" "}
                  </span>
                </div>
                <div className="text-white ml-10">
                  <p className="text-2xl font-bold">043963219</p>
                </div>
                <div
                  className={`bg-[#000] fixed top-0 left-0 flex justify-center items-center h-screen w-screen bg-opacity-40 ${
                    dropdownIsOpen ? " z-50 block" : "  -z-50 hidden"
                  }`}
                >
                  <div
                    className={` p-2 bg-white px-8 rounded-lg py-4 pb-6 right-0 rtl:left-0 w-[400px] rtl:right-auto shadow-xl top-0 left-0 `}
                  >
                    {otp ? (
                      <div className="otp w-full mt-8 flex-wrap">
                        <label
                          htmlFor=""
                          className="text-xl w-full my-4 flex capitalize"
                        >
                          {t("enter the code that was sent to")}{" "}
                        </label>
                        <form
                          className="relative gap-4 flex items-center justify-start  "
                          id="otp"
                        >
                          {/* <input type="text" onChange={(event) => setnumber1(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' /> */}
                          {/* <input type="text" onChange={(event) => setnumber2(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' /> */}
                          {/* <input type="text" onChange={(event) => setnumber3(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' /> */}
                          {/* <input type="text" onChange={(event) => setnumber4(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' /> */}
                          {/* <input type="text" onChange={(event) => setnumber5(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' /> */}
                          <OtpInput
                            value={otpNumber}
                            onChange={(e) => {
                              setOtpNumber(e);
                            }}
                            numInputs={6}
                            className={`block justify-center rounded-lg border-2 border-mainColor text-center mx-1 w-20 h-20 text-3xl`}
                            focusStyle={{ display: "block !important" }}
                            isInputNum={true}
                            isDisabled={buttonLoading}
                          />
                        </form>
                        <div className="flex justify-between items-center  mt-12">
                          <button
                            onClick={handleSend}
                            className="my-2 font-bold text-2xl text-mainColor"
                          >
                            {" "}
                            {t("Resend Code")}{" "}
                          </button>
                          {/* <button onClick={handleVer} className='bg-mainColor flex p-4 px-8 my-4 rounded-xl items-end  text-white font-bold text-2xl ' >Continue </button> */}
                          <button
                            onClick={handleVer}
                            className="mainBtn blueBtn px-4 rtl:ml-0 ltr:ml-auto rtl:mr-auto ltr:mr-0 py-1"
                          >
                            {buttonLoading
                              ? `${t("Loading . . .")}`
                              : `${t("Continue")}`}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <h3 className="text-2xl py-4 border-b border-mainColor font-bold flex items-center justify-between">
                          {" "}
                          {t("Login")}{" "}
                          <Link
                            to="/Signup"
                            className="font-bold cursor-pointer text-mainColor"
                          >
                            {" "}
                            {t("Sign up")}{" "}
                          </Link>{" "}
                          <span
                            className="cursor-pointer"
                            onClick={closeDropDown}
                          >
                            {" "}
                            X
                          </span>{" "}
                        </h3>
                        <p className="text-xl py-4">
                          {" "}
                          {t("your mobile number")}
                        </p>
                        <div className="form-group flex items-center justify-center border border-mainColor  p-4 rounded-lg text-2xl">
                          <label
                            htmlFor=""
                            className="flex items-center justify-center rtl:border-l ltr:border-r px-2 gap-4 rtl:ml-4 ltr:mr-4"
                          >
                            {" "}
                            <img
                              className="w-8 rtl:ml-2 ltr:mr-2"
                              src={
                                localStorage.i18nextLng == "ha" ? hsFlag : flag
                              }
                              alt=""
                            />{" "}
                            {localStorage.i18nextLng == "ha" ? "+234" : "+971"}{" "}
                          </label>
                          <input
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            minLength="6"
                            maxLength="9"
                            type="text"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            required
                            placeholder="52 555 5555"
                          />
                        </div>
                        {/* <button onClick={handleSend} className='OutlineBtn flex items-center justify-center p-4 rounded-lg text-xl relative  font-medium mt-4 ml-auto mr-0 bg-mainColor text-white border-2'>Continue</button> */}
                        <button
                          onClick={handleSend}
                          className="mainBtn blueBtn px-4 rtl:ml-0 ltr:ml-auto rtl:mr-auto ltr:mr-0 py-1 mt-12"
                        >
                          {t("Continue")}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div
                onClick={() => setDropdownIsOpen(true)}
                className="cursor-pointer flex items-center justify-center border-2 border-white p-2 px-4 rounded-3xl"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29.653"
                  height="29.653"
                  viewBox="0 0 29.653 29.653"
                >
                  <path
                    id="icons8_male_user_1"
                    d="M17.827,3A14.827,14.827,0,1,0,32.653,17.827,14.827,14.827,0,0,0,17.827,3ZM9.178,26.65c1.442-4.324,5.766-2.637,6.487-4.979V20.109a4.123,4.123,0,0,1-1.349-2.3c-.256-.022-.656-.276-.775-1.278a.948.948,0,0,1,.341-.935,4.968,4.968,0,0,1-.381-1.729c0-2.56,1.269-4.685,4.326-4.685a2.275,2.275,0,0,1,2.162,1.17c1.475,0,2.162,1.617,2.162,3.514a4.4,4.4,0,0,1-.381,1.729.945.945,0,0,1,.342.935c-.119,1-.519,1.255-.775,1.278a4.116,4.116,0,0,1-1.349,2.3v1.562c.72,2.344,5.045.656,6.487,4.98a12.354,12.354,0,0,1-17.3,0Z"
                    transform="translate(-3 -3)"
                    fill="#fff"
                  />
                </svg>
                {/* <span className='text-white font-bold mx-2 text-3xl'>{ localStorage.user.replace(/['"]+/g, '')} </span> */}
                {/* <span onClick={Logout} className='text-white font-bold mx-2 text-3xl'>Logout </span> */}
                {localStorage.user && (
                  <Link
                    to="/Profile"
                    className="text-white font-bold mx-2 text-3xl"
                    id="userName"
                  >
                    {userName}{" "}
                  </Link>
                )}
              </div>
            )}

            {localStorage.token && (
              <button
                onClick={Logout}
                className="text-white font-bold mx-2 text-3xl"
              >
                {" "}
                {t("Logout")}{" "}
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default ScreenNav;
