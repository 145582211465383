import { t } from 'i18next'
import React from 'react'
import Lottie from 'react-lottie';
import data from '../../../Lotti/data.json'
import dataAr from '../../../Lotti/dataAr.json'

import dataMobileAr from '../../../Lotti/mobileArabic.json'
import dataMobile from '../../../Lotti/mobileEng.json'

const defaultOptions = {
     loop: true,
     autoplay: true,
     animationData: localStorage.i18nextLng === 'ar' ? dataAr : data ,
     rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
     }
};


const MobileOptions = {
     loop: true,
     autoplay: true,
     animationData: localStorage.i18nextLng === 'ar' ? dataMobileAr : dataMobile,
     rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
     }
};
const HowItWorks = () => {
     return (
          <div id="HowItWorks" className='py-48 my-24 bg-[#1ec2e35c]'>
               <div className="container mx-auto px-4 lg:px-0">
                    <div className="row flex items-center justify-center flex-wrap">
                         <div className="w-full lg:w-2/3 text-center">
                              <h3 className='text-4xl xl:text-[3.5rem] mb-24  text-white font-bold'>{t('How It Works')}</h3>
                              <p className='text-2xl leading-loose mb-8 mt-4'>
                                   {t('You are guaranteed to be happy with Dangi cleaners. As we offer one of the most professional cleaning services in the UAE. High standard services – at no extra cost to you…')}
                              </p>

                         </div>
                    </div>
                    <div className="row flex items-center justify-center flex-wrap">
                         {
                              window.innerWidth > 767 ?
                                   <Lottie options={defaultOptions} />
                                   :
                                   <Lottie options={MobileOptions} />
                         }
                    </div>
               </div>
          </div >
     )
}

export default HowItWorks