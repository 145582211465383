import React from 'react'
import Layout from '../Component/Layout'
import Banner from '../Component/HomeSections/Banner/Banner'
import BestServices from '../Component/HomeSections/BestServices/BestServices'
import OurServices from '../Component/OurServices/OurServices'
import WhyDangiCleaners from '../Component/WhyDangiCleaners/WhyDangiCleaners'
import Form from '../Component/Form/Form'
import { useEffect } from 'react'
import About from '../Component/About/About'
import HowItWorks from '../Component/About/HowItWorks/HowItWorks'

const Home = () => {
  useEffect(() => {
    localStorage.removeItem('bookingObj');
  }, [])

  return (
    <Layout>
      <Banner />
      <About />
      <HowItWorks />
      <OurServices />
      <BestServices />
      <WhyDangiCleaners />
      <Form />
    </Layout>
  )

}

export default Home