import { t } from 'i18next'
import { AiOutlineStop } from 'react-icons/ai'

const Error = (props) => {
  return (
    <div className="alert error flex flex-wrap  mx-auto h-auto z-50 bg-white relative  shadow-md rounded-lg">
      <div className="relative w-full overflow-hidden  rounded-lg" >
        <div className="errorTitle flex items-center justify-start bg-red h-24 px-6 w-full">
          <AiOutlineStop className='text-white text-6xl' />
          <p className='text-4xl text-white rtl:mr-4 ltr:ml-4'>{t('Alert')} </p>
        </div>

        <div className=" px-6 py-2 errorDetails bg-white flex items-center justify-center h-auto w-full  flex-col">
          <AiOutlineStop className='text-red text-9xl' />
          <span className="text-black  text-4xl mt-4">{t('Error')}</span>
          <p className="text-xl text-black font-bold leading-10 text-center mb-8" >{props.children}</p>
        </div>
      </div>
    </div>
  )
}

export default Error