import wave from "../../../img/wavee.png";
import man from "../../../img/avatarBanner.png";
import Button from "../../UI/Button/Button";
import { GoCalendar } from "react-icons/go";
import "./index.scss";
import { t } from "i18next";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import arrow from "../../../img/right.svg";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} before:hidden`}
      style={{
        ...style,
        right: "20px",
        zIndex: "49",
        height: "40px",
        width: "40px",
        backgroundColor: "#ffe",
        borderRadius: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <img alt="" src={arrow} className="h-8 w-10" />
    </div>
  );
};
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} before:hidden`}
      style={{
        ...style,
        left: "20px",
        zIndex: "49",
        rotate: "180deg",
        height: "40px",
        width: "40px",
        backgroundColor: "#ffe",
        borderRadius: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: "translateY(20px)",
      }}
      onClick={onClick}
    >
      <img alt="" src={arrow} className="h-8 w-10" />
    </div>
  );
};

const settings = {
  dots: true,
  arrow: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const sliderContents = [
  {
    header: "We Are Your No.1",
    subText:
      "Cleaning services in Dubai, from AED25/HR. Book us now to get started!",
    image: "https://github.com/abdallasi/s3/blob/master/image1.png?raw=true",
  },
  {
    header: "Make your home sparkle",
    subText:
      "with our comprehensive cleaning services! Get your office in tip-top shape.",
    image: "https://github.com/abdallasi/s3/blob/master/image2.png?raw=true",
  },
  {
    header: "Are you satisfied with your office cleaning?",
    subText: "Get your office in tip-top shape with our professional cleaners.",
    image: "https://github.com/abdallasi/s3/blob/master/image3.png?raw=true",
  },
  {
    header: "Give your home the makeover it deserves!",
    subText: "Book a cleaning appointment with us now.",
    image: "https://github.com/abdallasi/s3/blob/master/image4.png?raw=true",
  },
  {
    header: "Let us take the hassle",
    subText: "out of cleaning – schedule your appointment today!",
    image: "https://github.com/abdallasi/s3/blob/master/image5.png?raw=true",
  },
  {
    header: "Make your life easier",
    subText:
      "and your home cleaner – book a cleaning appointment with us today!",
    image: "https://github.com/abdallasi/s3/blob/master/image3.png?raw=true",
  },
];

const index = () => {
  return (
    <div
      id="Banner"
      className="bg-mainColor min-h-[500px] md:min-h-[650px] overflow-hidden relative"
    >
      {/* <div className="bg-gradient-to-r from-mainColor h-full min-h-[650px] min-w-full absolute top-0 left-0" /> */}
      <img
        src={wave}
        alt=""
        className="absolute -bottom-6 lg:-bottom-16 left-0 w-screen z-40"
      />
      <div className="mx-auto">
        <div className="">
          <Slider {...settings} autoplay autoplaySpeed={4000} arrows>
            {sliderContents.map((item) => (
              <div>
                <div className="row flex justify-center min-h-[500px] md:min-h-[650px] flex-wrap text-center relative ">
                  <div className="w-full  div">
                    <div
                      class="min-w-screen w-screen max-w-screen h-full rounded-2xl bg-center bg-cover duration-500 absolute min-h-[500px] md:min-h-[650px] -z-10 bottom-0 "
                      style={{
                        backgroundImage: `url("${item.image}")`,
                      }}
                    ></div>
                    <div className="w-full  z-50 lg:py-0 flex pt-[100px] lg:pt-[100px] text-center justify-center lg:justify-start  rtl:lg:text-right ltr:lg:text-left px-4 md:px-[80px] flex-col">
                      <h3 className=" mb-[10px] text-[36px] md:text-[56px] font-medium w-full text-white leading-[0.9642857143] max-w-[600px]">
                        {t(`${item.header}`)}
                      </h3>
                      <h3 className="subColor text-4xl mb-10 font-medium w-full text-white text-[22px] max-w-[600px] ">
                        {t(`${item.subText}`)}
                      </h3>
                      <div className="text-center flex justify-center lg:justify-start mt-4 items-center mb-8 w-full">
                        <Link
                          to={
                            localStorage.token
                              ? "/Category/-1"
                              : `${window.location.pathname}?login=true`
                          }
                          className="mainBtn mt-4 py-4 px-6"
                        >
                          <GoCalendar className="mx-4" /> {t("Book Now")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default index;
