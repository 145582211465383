import { t } from 'i18next'
import React from 'react'
import { BsFillPatchCheckFill } from 'react-icons/bs'

const SuccessAlert = (props) => {
          return (

                    <div className="flex w-auto mx-auto overflow-hidden bg-white rounded-lg shadow-md fixed bottom-6 left-6  text-2xl border border-green z-50">
                              <div className="flex items-center justify-center   bg-green px-8">
                                        <BsFillPatchCheckFill className='text-white text-5xl' />
                              </div>

                              <div className="px-4 py-2 -mx-3">
                                        <div className="mx-3 py-4">
                                                  <span className="font-semibold text-green ">{t('Success')} </span>
                                                  <p className="text-xl text-gray-600 ">{props.success}</p> 
                                        </div>
                              </div>
                    </div>
          )
}

export default SuccessAlert