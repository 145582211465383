import { t } from 'i18next'
import React from 'react'
import { AiOutlineStop } from 'react-icons/ai'

const ErrorAlert = (props) => {
          return (

                    <div className="flex w-auto mx-auto overflow-hidden bg-white rounded-lg shadow-md fixed bottom-6 left-6  text-2xl border border-red z-50">
                              <div className="flex items-center justify-center   bg-red px-8">
                                        <AiOutlineStop className='text-white text-5xl' />
                              </div>

                              <div className="px-4 py-2 -mx-3">
                                        <div className="mx-3 py-4">
                                                  <span className="font-semibold text-red ">{t('Error')}</span>
                                                  <p className="text-xl text-gray-600 ">{props.error}</p> 
                                        </div>
                              </div>
                    </div>
          )
}

export default ErrorAlert