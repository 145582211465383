import axios from 'axios';
import React, { useEffect } from 'react'
import { configs, getBookingObj, getErrorInCard, setBookingObj, showLoading, updateCostCard, url } from '../../Commens/Commens';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { t } from 'i18next';

const Frequency = () => {
  const [FrequencyVal, setFrequencyVal] = useState('1')
  const [ferqency, setFerqency] = useState([])

  const { id } = useParams();

  useEffect(() => {
    const sendGetRequest = async () => {
      try {
        const resp = await axios.get(url + `categories/${id}`, configs);
        if (resp.status === 200) {
          const data = resp.data.data
          console.log(data.fequency_options)
          setFerqency(data.fequency_options)
        }
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
  }, [id]);


  function loadParamsObj(event) {
    const old = getBookingObj()
    old.category = `${id}`
    old.frequency_id = parseInt(FrequencyVal)
    const data = old
    const configsPost = configs
    configsPost.data = data
    axios.post(url + `calculate_price`, data, configsPost).then(response => {
      if (response.status === 404) { console.log(response); return; }
      if (response.status === 400) { console.log(response); return; }
      if (response.status === 200) {
        const data = response.data.data;
        updateCostCard(data)
        showLoading(false)
      }
    }).catch(error => {
      console.log(error.message)
      const data = error;
      getErrorInCard(data)
      showLoading(false)
    })
    setBookingObj(old)
  }

  loadParamsObj()

  return (
    <div className="frequancy mt-8" >
      <h3 className='font-bold text-3xl '>{t('How often do you need your professional?')}</h3>

      {
        ferqency.map((item, index) => (
          <label className="frequancyItem flex items-center my-16 w-full radioBox " key={index}>
            <div className="w-12">
                <input type="radio" name="radio" value={item.id} onChange={(e) => { setFrequencyVal(e.target.value); console.log(e.target.value) }}  checked={item.id == FrequencyVal && true } />
              <span className="checkmark"></span>
            </div>
            <div className="title w-full">
              <h3 className='text-2xl font-bold '> {item.name}  </h3>
              <p className='text-2xl my-2'>{item.description}</p>
            </div>
          </label>
        ))
      }

      {/* 
      <label className="frequancyItem flex items-center my-16 w-full radioBox ">
        <div className="w-12">
          <input type="radio" name="radio" value='2' />
          <span className="checkmark"></span>
        </div>
        <div className="title w-full">
          <h3 className='text-2xl font-bold '> {t('Bi-weekly')}  </h3>
          <p className='text-2xl my-2'>{t('Book a cleaning for one time only')}</p>
        </div>
      </label>

      <label className="frequancyItem flex items-center my-16 w-full radioBox ">
        <div className="w-12">
          <input type="radio" name="radio" value='3' />
          <span className="checkmark"></span>
        </div>
        <div className="title w-full">
          <h3 className='text-2xl font-bold '> {t('Weekly')}  </h3>
          <p className='text-2xl my-2'>{t('Book a cleaning for one time only')}</p>
        </div>
      </label> */}

    </div>
  )
}

export default Frequency