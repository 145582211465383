import Layout from "../Component/Layout";
import { Link, useParams } from "react-router-dom";
import { configs, url } from "../Commens/Commens";
import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "../Component/UI/Loader/Loader";

const Category = () => {
  const { id } = useParams();
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [service, setServices] = useState({});
  useEffect(() => {
    localStorage.removeItem("bookingObj");
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(url + `categories?parent=${id}`, configs)
      .then((response) => {
        if (response.status === 404) {
          setIsNotFound(true);
          return;
        }
        if (response.status === 400) {
          setIsNotValid(true);
          return;
        }
        const data = response.data.data;
        setServices(data.slice(0, 17));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  if (isNotFound) {
    return <p>NotFound</p>;
  }
  if (isNotValid) {
    return <p className="error">Sorry! The reservation ID is invalid.</p>;
  }
  if (isLoading) {
    return (
      <div className="Loading h-screen flex items-center justify-center">
        {" "}
        <Loader />{" "}
      </div>
    );
  }

  return (
    <Layout>
      <div id="Category" className="mt-12 lg:mt-48 flex justify-center ">
        <div className="container mx-auto">
          <div className="row flex items-center justify-center flex-wrap h-full">
            {service.map((item, index) => (
              <div
                className=" relative p-2  w-1/2 md:w-1/4 xl:w-1/6 my-4"
                key={index}
              >
                <div className="h-72">
                  <Link
                    // to={
                    //   localStorage.token
                    //     ? `/ServiceDetails/${item.id}`
                    //     : `${window.location.pathname}?login=true`
                    // }
                    to={
                      !localStorage.token
                        ? `${window.location.pathname}?login=true`
                        : item.has_sub_categories
                        ? `/Category/${item.id}`
                        : `/ServiceDetails/${item.id}`
                    }
                    className="serviceCard transition-all transition-900 flex items-center justify-center flex-col border-2 border-mainColor w-full h-full  rounded-xl p-4 text-center"
                  >
                    <img src={item.icon} alt="" className="w-28 mb-8 mx-auto" />
                    <h3 className="text-3xl  font-medium text-subColor">
                      {item.name}
                    </h3>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Category;
