import { useEffect, useState } from "react";
import { BsJustifyLeft } from "react-icons/bs";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Lang from "../Lang";
import flag from "../../img/uae.png";
import hsFlag from "../../img/ng.png";
import fav from "../../img/fav.png";
// import Sidebar from './Sidebar'
import axios from "axios";
import { configs, url } from "../../Commens/Commens";
import Sidebar from "./Sidebar";
import { t } from "i18next";
import OtpInput from "react-otp-input";

const MobileNav = (props) => {
  const navigate = useNavigate();
  const [openSideBar, setSideBar] = useState(false);
  const [otp, setOtp] = useState(false);
  const [phone, setPhone] = useState("");
  const [number1, setnumber1] = useState("");
  const [number2, setnumber2] = useState("");
  const [number3, setnumber3] = useState("");
  const [number4, setnumber4] = useState("");
  const [number5, setnumber5] = useState("");
  const opt = [number1, number2, number3, number4, number5];
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [otpNumber, setOtpNumber] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var userName = localStorage.getItem("user");

  const closeDropDown = () => {
    navigate(`${window.location.pathname}`);
    setDropdownIsOpen(false);
  };

  const handleSend = (event) => {
    if (phone.length < 8) {
      console.log("less 8");
      return;
    }
    event.preventDefault();
    let data = new FormData();
    data.append(
      "phone",
      `${localStorage.i18nextLng == "ha" ? "+234" : "+971"}${phone}`
    );
    axios
      .post(url + "otp/request", data, configs)
      .then((response) => {
        setOtp(true);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleVer = (event) => {
    const otpString = opt.join("");
    event.preventDefault();
    let data = new FormData();
    data.append(
      "phone",
      `${localStorage.i18nextLng == "ha" ? "+234" : "+971"}${phone}`
    );
    data.append("otp", otpNumber);
    axios
      .post(url + "otp/verify", data, configs)
      .then((response) => {
        if (localStorage.token) {
          window.location.href = "/";
        }
        setTimeout(() => {
          if (!localStorage.token) {
            navigate("/Signup");
          }
        }, 3000);
        localStorage.setItem("token", response.data.data.token);
        setOtp(false);
        setnumber1("");
        setnumber2("");
        setnumber3("");
        setnumber4("");
        setnumber5("");
        // localStorage.setItem('user', JSON.stringify(response.data.data.name));
        localStorage.setItem("user", response.data.data.name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenSideBar = () => {
    // setOpenSideBar(!openSideBar)
    setSideBar(true);
  };

  const closeSideBar = () => {
    setSideBar(false);
  };

  const [searchParams] = useSearchParams();

  const param = [searchParams.get("login")];

  useEffect(() => {
    if (searchParams.get("login") === "true") {
      setDropdownIsOpen(true);
    }
  }, [param]);

  return (
    <>
      <div className="bg-mainColor fixed w-full top-0 p-4 left-0 z-50">
        <ul className="flex items-center justify-between">
          <li className="flex items-center gap-4 w-1/2">
            <button onClick={handleOpenSideBar}>
              <BsJustifyLeft className="text-6xl text-white" />
            </button>
            <Link to="/">
              <img src={fav} alt="" />{" "}
            </Link>
          </li>
          <li className="flex items-center justify-end">
            <Lang />
            {!localStorage.token ? (
              <>
                <div
                  onClick={() => setDropdownIsOpen(true)}
                  className="cursor-pointer flex items-center justify-center border-2 border-white p-2 px-4 rounded-3xl"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.653"
                    height="29.653"
                    viewBox="0 0 29.653 29.653"
                  >
                    <path
                      id="icons8_male_user_1"
                      d="M17.827,3A14.827,14.827,0,1,0,32.653,17.827,14.827,14.827,0,0,0,17.827,3ZM9.178,26.65c1.442-4.324,5.766-2.637,6.487-4.979V20.109a4.123,4.123,0,0,1-1.349-2.3c-.256-.022-.656-.276-.775-1.278a.948.948,0,0,1,.341-.935,4.968,4.968,0,0,1-.381-1.729c0-2.56,1.269-4.685,4.326-4.685a2.275,2.275,0,0,1,2.162,1.17c1.475,0,2.162,1.617,2.162,3.514a4.4,4.4,0,0,1-.381,1.729.945.945,0,0,1,.342.935c-.119,1-.519,1.255-.775,1.278a4.116,4.116,0,0,1-1.349,2.3v1.562c.72,2.344,5.045.656,6.487,4.98a12.354,12.354,0,0,1-17.3,0Z"
                      transform="translate(-3 -3)"
                      fill="#fff"
                    />
                  </svg>
                  <span className="text-white font-bold mx-2 text-xl flex  w-max">
                    {t("Login")}
                  </span>
                </div>
                <div
                  className={`bg-[#000] fixed top-0 left-0 flex justify-center items-center h-screen w-screen bg-opacity-40 ${
                    dropdownIsOpen ? " z-50 block" : "  -z-50 hidden"
                  }`}
                >
                  <div
                    onMouseLeave={() => setDropdownIsOpen(false)}
                    className={` p-2 bg-white px-8 rounded-lg py-4 pb-6 right-0 rtl:left-0 w-full max-w-[400px] rtl:right-auto shadow-xl top-0 left-0 `}
                  >
                    {otp ? (
                      <div className="otp w-full mt-8 flex-wrap">
                        <label
                          htmlFor=""
                          className="text-xl w-full my-4 flex capitalize"
                        >
                          {t("enter the code that was sent to")}
                        </label>
                        <form
                          className="relative gap-4 flex items-center justify-start  "
                          id="otp"
                        >
                          {/* <input type="tel" onChange={(event) => setnumber1(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' /> */}
                          {/* <input type="tel" onChange={(event) => setnumber2(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' /> */}
                          {/* <input type="tel" onChange={(event) => setnumber3(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' /> */}
                          {/* <input type="tel" onChange={(event) => setnumber4(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' /> */}
                          {/* <input type="tel" onChange={(event) => setnumber5(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' /> */}
                          <OtpInput
                            value={otpNumber}
                            onChange={(e) => {
                              setOtpNumber(e);
                              console.log(e);
                            }}
                            numInputs={6}
                            className={`block justify-center rounded-lg border-2 border-mainColor text-center mx-1 w-20 h-20 text-3xl`}
                            focusStyle={{ display: "block !important" }}
                            isInputNum={true}
                          />
                        </form>
                        <div className="flex justify-between mt-6">
                          <button
                            onClick={handleSend}
                            className="my-2 font-bold text-2xl text-mainColor"
                          >
                            {t("Resend Code")}{" "}
                          </button>
                          <button
                            onClick={handleVer}
                            className="bg-mainColor flex p-4 px-8 my-4 rounded-xl items-end  text-white font-bold text-2xl "
                          >
                            {t("Continue")}{" "}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <h3 className="text-2xl py-4 border-b border-mainColor font-bold flex items-center justify-between">
                          {t("Login")}{" "}
                          <Link
                            to="/Signup"
                            className="font-bold cursor-pointer text-mainColor"
                          >
                            {" "}
                            {t("Sign up")}{" "}
                          </Link>{" "}
                          <span
                            className="cursor-pointer"
                            onClick={closeDropDown}
                          >
                            {" "}
                            X
                          </span>{" "}
                        </h3>
                        <p className="text-xl py-4">
                          {" "}
                          {t("your mobile number")}{" "}
                        </p>
                        <div className="form-group flex items-center justify-center border border-mainColor  p-4 rounded-lg text-2xl">
                          <label
                            htmlFor=""
                            className="flex items-center justify-center rtl:border-l ltr:border-r px-2 gap-4 rtl:ml-4 ltr:mr-4"
                          >
                            {" "}
                            <img
                              className="w-8 rtl:ml-2 ltr::mr-2"
                              src={
                                localStorage.i18nextLng == "ha" ? hsFlag : flag
                              }
                              alt=""
                            />{" "}
                            {localStorage.i18nextLng == "ha" ? "+234" : "+971"}{" "}
                          </label>
                          <input
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            minLength="6"
                            maxLength="12"
                            type="tel"
                            placeholder="52 555 5555"
                          />
                        </div>
                        <button
                          onClick={handleSend}
                          className="OutlineBtn flex items-center justify-center p-4 rounded-lg text-xl relative  font-medium mt-4 ml-auto rtl:ml-0 rtl:mr-auto ltr:mr-0 bg-mainColor text-white border-2"
                        >
                          {t("Continue")}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div
                onClick={() => setDropdownIsOpen(true)}
                className="cursor-pointer flex items-center justify-center border-2 border-white p-2 px-4 rounded-3xl"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29.653"
                  height="29.653"
                  viewBox="0 0 29.653 29.653"
                >
                  <path
                    id="icons8_male_user_1"
                    d="M17.827,3A14.827,14.827,0,1,0,32.653,17.827,14.827,14.827,0,0,0,17.827,3ZM9.178,26.65c1.442-4.324,5.766-2.637,6.487-4.979V20.109a4.123,4.123,0,0,1-1.349-2.3c-.256-.022-.656-.276-.775-1.278a.948.948,0,0,1,.341-.935,4.968,4.968,0,0,1-.381-1.729c0-2.56,1.269-4.685,4.326-4.685a2.275,2.275,0,0,1,2.162,1.17c1.475,0,2.162,1.617,2.162,3.514a4.4,4.4,0,0,1-.381,1.729.945.945,0,0,1,.342.935c-.119,1-.519,1.255-.775,1.278a4.116,4.116,0,0,1-1.349,2.3v1.562c.72,2.344,5.045.656,6.487,4.98a12.354,12.354,0,0,1-17.3,0Z"
                    transform="translate(-3 -3)"
                    fill="#fff"
                  />
                </svg>
                {/* <span className='text-white font-bold mx-2 text-3xl'>{ localStorage.user.replace(/['"]+/g, '')} </span> */}
                {/* <span onClick={Logout} className='text-white font-bold mx-2 text-3xl'>Logout </span> */}
                {localStorage.user && (
                  <Link
                    to="/Profile"
                    className="text-white font-bold mx-2 text-xl w-max"
                    id="userName"
                  >
                    {userName}{" "}
                  </Link>
                )}
              </div>
            )}
          </li>
        </ul>
      </div>
      {openSideBar && <Sidebar onChangeSideBar={closeSideBar} />}
    </>
  );
};

export default MobileNav;
