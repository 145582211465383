import React, { useEffect } from 'react'
import Layout from '../Component/Layout'
import WhyDangiCleaners from '../Component/WhyDangiCleaners/WhyDangiCleaners'
import logo from '../img/h_logo.png'
import wave from '../img/wavee.jpg'
import about from '../img/about.png'
import { t } from 'i18next'

const About = () => {
  useEffect(() => {
    localStorage.removeItem('bookingObj');
  }, [])
  return (
    <Layout>
      <div id="about" className='bg-mainColor relative min-h-screen overflow-hidden '>
        <img src={wave} alt="" className='absolute -bottom-16 left-0 w-screen' />
        <div className="container mx-auto px-4 lg:px-0">
          <div className="row flex items-center justify-center min-h-screen flex-wrap">
            <div className="w-full md:w-1/2 relative z-30">
              <img src={logo} alt="" />
              <p className='text-2xl leading-loose mb-8 mt-4 text-white'>
                {t('p2')}
              </p>
              <p className='text-2xl leading-loose mb-8 mt-4 text-white'>
                {t('p1')}
              </p>
            </div>
            <div className="w-full md:w-1/2 flex items-center justify-center h-72">
              <img src={about} alt="" className='relative w-full' />
            </div>
          </div>
        </div>
      </div >
      <WhyDangiCleaners />

    </Layout>
  )
}

export default About