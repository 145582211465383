import { Link } from "react-router-dom";
import v_logo from "../../img/v_logo.svg";
import wave from "../../img/footer.jpg";
import {
  AiOutlineWhatsApp,
  AiOutlineInstagram,
  AiOutlineMail,
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { BiPhoneCall } from "react-icons/bi";
import { FiFacebook } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { t } from "i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import { configs, url } from "../../Commens/Commens";

const Footer = () => {
  const [service, setServices] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios.get(url + "categories?parent=-1", configs).then((response) => {
      const data = response.data.data;
      setCategories(data);
    });
    axios
      .get(url + "categories?filter=home", configs)
      .then((response) => {
        const data = response.data.data;
        setServices(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <footer className="bg-mainColor mt-24 pt-48 lg:pt-96 relative overflow-hidden">
        <img src={wave} alt="" className="absolute top-0 left-0 min-w-full " />
        <div className="container mx-auto relative pb-24">
          <div className="row flex items-start justify-center flex-wrap text-center md:text-left">
            <div className="w-full mt-8 lg:w-1/5 ltr:pr-2 flex items-center">
              <img src={v_logo} alt="" className="mx-auto mb-4 w-48 lg:w-64" />
            </div>
            <div className="w-full md:w-1/3 mt-8 lg:w-1/5 ltr:pr-2">
              <ul>
                {service.slice(0, 6).map((item, index) => (
                  <li
                    key={index}
                    className="mb-4 text-2xl text-white ltr:md:text-left rtl:md:text-right rtl:pr-4 ltr:pl-4"
                  >
                    <Link
                      to={
                        localStorage.token
                          ? `/ServiceDetails/${item.id}`
                          : `${window.location.pathname}?login=true`
                      }
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full md:w-1/3 mt-8 lg:w-1/5 ltr:pr-2">
              <ul>
                {categories.slice(0, 6).map((item, index) => (
                  <li
                    key={index}
                    className="mb-4 text-2xl text-white ltr:md:text-left rtl:md:text-right rtl:pr-4 ltr:pl-4"
                  >
                    <Link to={`/Category/${item.id}`}>{item.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full md:w-1/3 mt-8 lg:w-1/5 ltr:pr-2">
              <ul>
                <li className="mb-4 text-2xl text-white ltr:md:text-left rtl:md:text-right rtl:pr-4 ltr:pl-4">
                  <Link to="/">Home</Link>
                </li>
                <li className="mb-4 text-2xl text-white ltr:md:text-left rtl:md:text-right rtl:pr-4 ltr:pl-4">
                  <Link
                    to={
                      localStorage.token
                        ? "/Category/-1"
                        : `${window.location.pathname}?login=true`
                    }
                  >
                    Categories
                  </Link>
                </li>
                <li className="mb-4 text-2xl text-white ltr:md:text-left rtl:md:text-right rtl:pr-4 ltr:pl-4">
                  <Link to="/About">About us</Link>
                </li>
                <li className="mb-4 text-2xl text-white ltr:md:text-left rtl:md:text-right rtl:pr-4 ltr:pl-4">
                  <Link to="/contact">Contact us</Link>
                </li>
                <li className="mb-4 text-2xl text-white ltr:md:text-left rtl:md:text-right rtl:pr-4 ltr:pl-4">
                  <Link to="/disclaimer">Disclaimer</Link>
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-1/5 text-center ltr:lg:text-left rtl:lg:text-right  flex flex-wrap items-center mt-8 ltr:pl-4 rtl:pr-4">
              <ul className="w-full mt-6 ">
                <li className="w-full mb-4 text-3xl text-white text-center ltr:lg:text-left rtl:lg:text-right ">
                  <a
                    href="https://www.google.com/maps/place/Al+Ansari+Exchange,+I+Rise+Tower+Barsha+Heights+Branch/@25.0972324,55.180873,15z/data=!4m2!3m1!1s0x0:0x55309116c7f53a0f?sa=X&ved=2ahUKEwiht8zKyLD8AhWDTaQEHZcNDcwQ_BJ6BAhyEAg"
                    className="w-full flex items-center justify-center lg:justify-start "
                  >
                    {" "}
                    <span className="w-12">
                      <GoLocation className="ltr:mr-4 rtl:ml-4  text-3xl" />
                    </span>{" "}
                    Rise Tower, Level 08, C05. Barsha Heights, Dubai, UAE.
                  </a>
                </li>
                <li className="w-full mb-4 text-3xl text-white text-center ltr:lg:text-left rtl:lg:text-right ">
                  <a
                    href="tel:+971565079563"
                    className="w-full flex items-center justify-center lg:justify-start "
                  >
                    {" "}
                    <span className="w-12">
                      <BiPhoneCall className="ltr:mr-4 rtl:ml-4  text-3xl" />
                    </span>{" "}
                    <b>+971 56 507 9563</b>{" "}
                  </a>
                </li>
                <li className="w-full mb-4 text-3xl text-white text-center ltr:lg:text-left rtl:lg:text-right ">
                  <a
                    href="mailto:info@dangiclean.com"
                    className="w-full flex items-center justify-center lg:justify-start "
                  >
                    <span className="w-12">
                      <AiOutlineMail className="ltr:mr-4 rtl:ml-4 text-3xl" />
                    </span>{" "}
                    info@dangiclean.com
                  </a>
                </li>
              </ul>
              <ul className="w-full flex items-center justify-center lg:justify-start mt-4">
                <li className="pr-4 text-5xl text-white">
                  <a href="https://www.instagram.com/dangi_cleaning_services/">
                    <AiOutlineInstagram />
                  </a>{" "}
                </li>
                <li className="pr-4 text-5xl text-white">
                  <a
                    href={`https://api.whatsapp.com/send?phone=${
                      window.innerWidth > 767 ? "+971565079563" : "+971565079563"
                    }`}
                  >
                    <AiOutlineWhatsApp />
                  </a>
                </li>
                <li className="pr-4 text-5xl text-white">
                  <a href="https://www.tiktok.com/@dangicleaners2022">
                    <FaTiktok />
                  </a>
                </li>
                <li className="pr-4 text-5xl text-white">
                  <a href="https://web.facebook.com/profile.php?id=100083302534930&_rdc=1&_rdr">
                    <FiFacebook />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="w-full text-center">
        <p className=" text-2xl  text-black my-8">
          {t("All rights reserved to Dangicleaners  | Designed by")}{" "}
          <a
            href="https://www.brainclickads.com"
            target="_blank"
            rel="noreferrer"
            className="font-bold text-mainColor"
          >
            {" "}
            {t("Brain Click")}{" "}
          </a>
          {`${new Date().getFullYear()}`}
        </p>
      </div>
    </>
  );
};

export default Footer;
