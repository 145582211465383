import { t } from 'i18next'
import React from 'react'

const About = () => {
     return (
          <div id="About" className='pt-48 '>
               <div className="container mx-auto px-4 lg:px-0">
                    <div className="row flex items-center justify-center flex-wrap">
                         <div className="w-full lg:w-2/3 text-center">
                              <h3 className='text-4xl xl:text-[3.5rem] mb-24  text-subColor font-bold'>{t('About us')}</h3>
                              <p className='text-2xl leading-loose mb-8 mt-4'>
                                   {t('p2')}
                              </p>
                              <p className='text-2xl leading-loose mb-8 mt-4'>
                                   {t('p1')}
                              </p>


                         </div>
                    </div>
               </div>
          </div>
     )
}

export default About