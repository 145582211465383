import React, { useEffect, useState } from "react";
import Layout from "../Component/Layout";
import wave from "../img/wavee.jpg";
import contact from "../img/contact.png";
import {
  AiOutlineSend,
  AiOutlineWhatsApp,
  AiOutlineInstagram,
  AiOutlineMail,
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import axios from "axios";
import { configs, url } from "../Commens/Commens";
import { t } from "i18next";
import ErrorAlert from "../Component/UI/Alert/ErrorAlert";
import SuccessAlert from "../Component/UI/Alert/SuccessAlert";
import { BiPhoneCall } from "react-icons/bi";

const Contact = () => {
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  useEffect(() => {
    localStorage.removeItem("bookingObj");
  }, []);
  const handleSubmit = () => {
    console.log(fullName, mobileNumber, message);
    if (
      fullName.trim("").length < 6 ||
      mobileNumber.trim("").length < 6 ||
      !message
    ) {
      setError(`${t("check your data")}`);
      setTimeout(() => {
        setError("");
      }, 5000);
      return;
    }
    let data = new FormData();
    data.append("name", fullName);
    data.append("phone", mobileNumber);
    data.append("message", message);
    axios
      .post(url + `contact`, data, configs)
      .then((response) => {
        console.log(response.data);
        setFullName("");
        setMobileNumber("");
        setMessage("");
        setSuccess(response.data.message);
        setTimeout(() => {
          setSuccess("");
        }, 5000);
      })
      .catch((error) => {
        console.log(error.message);
        setError(error.response);
        setTimeout(() => {
          setError("");
        }, 5000);
      });
  };

  return (
    <Layout>
      {error && <ErrorAlert error={error} />}
      {success && <SuccessAlert success={success} />}
      <div
        id="Contact"
        className="bg-mainColor relative  overflow-hidden py-12"
      >
        <img
          src={wave}
          alt=""
          className="absolute -bottom-16 left-0 w-screen hidden lg:block"
        />
        <div className="container mx-auto">
          <div className="row flex items-center justify-center flex-wrap">
            <div className="w-full md:w-1/2 flex items-center justify-center">
              <img
                src={contact}
                alt=""
                className="relative w-full h-auto lg:mb-64"
              />
            </div>

            <div className="w-full md:w-1/2 relative z-30">
              <div className="Form mt-24 py-16 px-4 lg:px-8 rounded-xl bg-white mb-[150px]">
                <div className="row flex items-center justify-center flex-wrap text-right ">
                  <div className="w-full md:w-1/2 rtl:text-right ltr:text-left p-4">
                    <label
                      className="text-gray text-3xl rtl:text-right"
                      htmlFor="FullName"
                    >
                      {t("Full Name")}
                    </label>
                    <input
                      onChange={(e) => setFullName(e.target.value)}
                      value={fullName}
                      placeholder={t("Full Name")}
                      autoComplete="true"
                      id="FullName"
                      type="text"
                      className="block w-full px-4 py-4 mt-2 text-gray-700  border border-mainColor text-3xl rounded-md "
                    />
                  </div>
                  <div className="w-full md:w-1/2 rtl:text-right ltr:text-left p-4">
                    <label className="text-gray text-3xl " htmlFor="mobile">
                      {" "}
                      {t("Mobile Number")}{" "}
                    </label>
                    <input
                      onChange={(e) => setMobileNumber(e.target.value)}
                      value={mobileNumber}
                      placeholder={t("Mobile Number")}
                      autoComplete="true"
                      id="mobile"
                      type="tel"
                      className="block w-full px-4 py-4 mt-2 text-gray-700  border border-mainColor text-3xl rounded-md "
                    />
                  </div>
                  <div className="w-full rtl:text-right ltr:text-left p-4">
                    <label className="text-gray text-3xl " htmlFor="mobile">
                      {" "}
                      {t("Message")}
                    </label>
                    <textarea
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                      placeholder={t("Write you Message")}
                      className="block w-full px-4 py-4 mt-2 text-gray-700  border border-mainColor text-3xl rounded-md "
                    ></textarea>
                  </div>

                  <button
                    onClick={handleSubmit}
                    className="mainBtn blueBtn mt-8 px-8 py-2"
                  >
                    <AiOutlineSend className="mx-4" />
                    {t("Send")}
                  </button>
                </div>
              </div>
              {/* <ul className='w-full mt-16 '>
                <li className='w-full mb-4 text-3xl text-white text-center ltr:lg:text-left rtl:lg:text-right '>
                  <a href='https://www.google.com/maps/place/Al+Ansari+Exchange,+I+Rise+Tower+Barsha+Heights+Branch/@25.0972324,55.180873,15z/data=!4m2!3m1!1s0x0:0x55309116c7f53a0f?sa=X&ved=2ahUKEwiht8zKyLD8AhWDTaQEHZcNDcwQ_BJ6BAhyEAg' className='w-full flex items-center justify-center lg:justify-start '> <span className="w-12"><GoLocation className='ltr:mr-4 rtl:ml-4  text-3xl' /></span>  I Rise Tower, Level 08, C05. Barsha Heights, Dubai, UAE.
                  </a>
                </li>
                <li className='w-full mb-4 text-3xl text-white text-center ltr:lg:text-left rtl:lg:text-right '>
                  <a href='tel:+043963147' className='w-full flex items-center justify-center lg:justify-start '> <span className="w-12"><BiPhoneCall className='ltr:mr-4 rtl:ml-4  text-3xl' /></span>  <b>04396 3147</b> </a>
                </li>
                <li className='w-full mb-4 text-3xl text-white text-center ltr:lg:text-left rtl:lg:text-right '>
                  <a href='tel:+971565079563' className='w-full flex items-center justify-center lg:justify-start '> <span className="w-12"><BiPhoneCall className='ltr:mr-4 rtl:ml-4  text-3xl' /></span>  <b>+971-56 507 9563</b> </a>
                </li>
                <li className='w-full mb-4 text-3xl text-white text-center ltr:lg:text-left rtl:lg:text-right '>
                  <a href='mailto:info@dangiclean.com' className='w-full flex items-center justify-center lg:justify-start '><span className='w-12'><AiOutlineMail className='ltr:mr-4 rtl:ml-4 text-3xl' /></span>  info@dangiclean.com</a>
                </li>
              </ul> */}
              {/* <ul className='flex flex-wrap items-center justify-between w-full font-bold lg:mb-64'>

                <ul className='w-full flex items-center justify-center lg:justify-start mt-4'>
                  <li className='pr-4 text-5xl text-white'>
                    <a href="https://www.instagram.com/dangi_cleaning_services/"><AiOutlineInstagram /></a> </li>
                  <li className='pr-4 text-5xl text-white'>
                    <a href={`https://api.whatsapp.com/send?phone=${window.innerWidth > 767 ? '971509185004' : '+971509185004'}`}><AiOutlineWhatsApp /></a></li>
                  <li className='pr-4 text-5xl text-white'>
                    <a href="https://www.tiktok.com/@dangicleaners2022"><FaTiktok /></a></li>
                  <li className='pr-4 text-5xl text-white'>
                    <a href="https://web.facebook.com/profile.php?id=100083302534930&_rdc=1&_rdr"><FiFacebook /></a></li>
                </ul>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
      <div className="map">
        <div className="row flex item-center justify-center">
          <div className="container mx-auto">
            <div className="w-full my-24">
              <iframe
                className="w-full rounded-3xl"
                style={{ height: "50rem" }}
                title="map"
                id="gmap_canvas"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Al%20Ansari%20Exchange,%20I%20Rise%20Tower%20Barsha%20Heights%20Branch+(Dangi%20Cleaners)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                {" "}
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
