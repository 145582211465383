import axios from "axios";
import React, { useState } from "react";
import { configs, getBookingObj, setBookingObj, url } from "../../Commens/Commens";
import uae from "../../img/uae.png";
import hsFlag from "../../img/ng.png";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import Error from "../UI/Alert/Error";
import Success from "../UI/Alert/Success";
import { t } from "i18next";
import ErrorAlert from "../UI/Alert/ErrorAlert";
import { useEffect } from "react";
import SuccessAlert from "../UI/Alert/SuccessAlert";

const Phone = (props) => {
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const name = useFormInput("");
  const email = useFormInput("");
  const gender = useFormInput("");
  const address1 = useFormInput("");
  const [otp, setOtp] = useState(false);
  const [signup, setSignup] = useState(false);

  const [phone, setPhone] = useState("");
  const countryJey = localStorage.i18nextLng;
  const [otpNumber, setOtpNumber] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // Payment
  const [cardNumber, setCardNumber] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [nameCustomer, setNameCustomer] = useState("");
  const [cvv, setCvv] = useState("");

  useEffect(() => {
    document.getElementById("NextButton").setAttribute("disabled", "disabled");

    if (localStorage.token) {
      if (
        cardNumber.length >= 10 &&
        expMonth.length >= 2 &&
        expYear.length >= 4 &&
        nameCustomer.length >= 4 &&
        cvv.length >= 3
      ) {
        document.getElementById("BookingButton").removeAttribute("disabled");
      } else {
        document.getElementById("BookingButton").setAttribute("disabled", "disabled");
      }
    }
  }, [cardNumber, expMonth, expYear, nameCustomer, cvv]);

  const old = getBookingObj();

  const handleSignup = (event) => {
    event.preventDefault();
    let data = new FormData();
    data.append("phone", `${countryJey == "ha" ? "+234" : "+971"}${phone}`);
    data.append("email", email.value);
    data.append("gender", gender.value);
    data.append("address1", address1.value);
    data.append("name", name.value);
    data.append("longitude", old.location_value.lng);
    data.append("latitude", old.location_value.lat);
    data.append("city", "dubai");
    setPhoneNumber(phone);
    axios
      .post(url + "signup", data, configs)
      .then((response) => {
        const token = response.data.data.token;
        localStorage.setItem("token", JSON.stringify(token));
        localStorage.setItem("user", response.data.data.name);
        setSuccess(response.data.message);
        setTimeout(() => {
          setSuccess("");
        }, 5000);
        setOtp(true);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setError(error.response.data.message);
        setTimeout(() => {
          setError("");
        }, 5000);
      });
  };

  const handleSend = (event) => {
    event.preventDefault();
    let data = new FormData();
    data.append("phone", `${countryJey == "ha" ? "+234" : "+971"}${phone}`);
    setPhoneNumber(phone);
    axios
      .post(url + "otp/request", data, configs)
      .then((response) => {
        setSuccess(response.data.message);
        setTimeout(() => {
          setSuccess("");
        }, 5000);
        setOtp(true);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setError(error.response.data.message);
        setTimeout(() => {
          setError("");
        }, 5000);
      });
  };

  const handleVer = () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("phone", `${countryJey == "ha" ? "+234" : "+971"}${phone}`);
    data.append("otp", otpNumber);
    axios
      .post(url + "otp/verify", data, configs)
      .then((response) => {
        const data = response.data.data;
        setSuccess(response.data.message);
        if (data === null) {
          setSignup(true);
        } else {
          const token = response.data.data.token;
          localStorage.setItem("token", token);
          localStorage.setItem("user", response.data.data.name);
        }
        setOtp(false);
        // localStorage.setItem('user', JSON.stringify(response.data.data));
        localStorage.setItem("user", response.data.data.name);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setError(error.response.data.message);
        setIsLoading(false);
        setTimeout(() => {
          setError("");
        }, 5000);
      });
  };

  useEffect(() => {
    if (otpNumber && otpNumber.length > 5) {
      handleVer();
    }
  }, [otpNumber]);

  function handleBooking() {
    const paymentObj = {};
    paymentObj.card_no = cardNumber;
    paymentObj.exp_date_month = expMonth;
    paymentObj.exp_date_year = expYear;
    paymentObj.name = nameCustomer;
    paymentObj.cvv = cvv;
    old.payment = paymentObj;
    old.token = localStorage.token;
    setBookingObj(old);
    const data = old;
    // const configsPost = configs
    // configsPost.data = data

    axios({
      method: "post", //you can set what request you want to be
      url: url + `book`,
      data: data,
      headers: {
        Accept: "application/json",
        lang: localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "en",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        console.log(response);
        setSuccess(response.data.message);
        setSuccess("");
        navigate(`/ThankYou?paymentLink=${response.data.payment_link}`, { replace: true });
      })
      .catch((error) => {
        console.log(error.response.data.error);
        setError(error.response.data.error);
        props.error(error.response.data.error);
        setTimeout(() => {
          setError();
        }, 5000);
      });
  }

  const handleBookingLater = () => {
    setIsLoading(true);
    const paymentObj = {};
    old.payment = paymentObj;
    old.token = localStorage.token;
    setBookingObj(old);
    const data = old;
    // const configsPost = configs
    // configsPost.data = data

    axios({
      method: "post", //you can set what request you want to be
      url: url + `book`,
      data: data,
      headers: {
        Accept: "application/json",
        lang: localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : "en",
        Authorization: `Bearer ${localStorage.getItem("token")?.replace(/['"]+/g, "")}`,
      },
    })
      .then((response) => {
        console.log(response);
        setSuccess(response.data.message);
        navigate("/ThankYou");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.error);
        setError(error.response.data.error);
        props.error(error.response.data.error);
        setTimeout(() => {
          setError();
        }, 5000);
        setIsLoading(false);
      });
  };

  if (localStorage.token) {
    handleBooking();
    return <span className="loader"></span>;
  }

  return (
    <div className=" relative overflow-hidden border-2 border-mainColor p-4 rounded-2xl mt-6">
      {/* {error && <ErrorAlert error={error} />} */}
      {success && <SuccessAlert success={success} />}
      <>
        {
          !localStorage.token ? (
            <>
              <h3 className="text-2xl font-bold my-6">{t("Log in or sign up")}</h3>
              <hr className="bg-mainColor text-mainColor" />
              {signup ? (
                <>
                  <h1 className="text-2xl font-bold my-6"> {t("Sign Up")} </h1>

                  <div className="form-group my-4">
                    <label htmlFor="" className="text-2xl flex capitalize">
                      {t("phone")}
                    </label>
                    <input
                      onChange={(e) => setPhone(e.target.value)}
                      value={`${localStorage.i18nextLng == "ha" ? "+234" : "+971"}${phone}`}
                      disabled
                      placeholder="Full name"
                      autoComplete="true"
                      id="mobile"
                      type="text"
                      className="border-2 border-mainColor rounded-xl block w-full p-2 mt-2 text-gray-700 bg-lightGray text-2xl cursor-not-allowed "
                    />
                  </div>
                  <div className="form-group my-4">
                    <label htmlFor="" className="text-2xl flex capitalize">
                      {t("Full name")}
                    </label>
                    <input
                      {...name}
                      placeholder={t("Full name")}
                      autoComplete="true"
                      id=""
                      type="text"
                      className="border-2 border-mainColor rounded-xl block w-full p-2 mt-2 text-gray-700 bg-white text-2xl  "
                    />
                  </div>
                  <div className="form-group my-4">
                    <label htmlFor="" className="text-2xl flex capitalize">
                      {t("email")}
                    </label>
                    <input
                      {...email}
                      placeholder={t("email")}
                      autoComplete="true"
                      id=""
                      type="email"
                      className="border-2 border-mainColor rounded-xl block w-full p-2 mt-2 text-gray-700 bg-white text-2xl  "
                    />
                  </div>
                  <div className="form-group my-4">
                    <label htmlFor="" className="text-2xl flex capitalize">
                      {t("Full Address")}
                    </label>
                    <input
                      {...address1}
                      placeholder={t("Full Address")}
                      autoComplete="true"
                      id=""
                      type="text"
                      className="border-2 border-mainColor rounded-xl block w-full p-2 mt-2 text-gray-700 bg-white text-2xl  "
                    />
                  </div>
                  <div className="form-group my-4">
                    <label htmlFor="" className="text-2xl flex capitalize">
                      {t("gender")}
                    </label>
                    <div className="flex justify-between items-center text-xl">
                      <div className="form-group w-full my-4">
                        <label className="radioBox text-xl">
                          {" "}
                          {t("Male")}
                          <input {...gender} type="radio" name="gender" value="male" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="form-group w-full my-4">
                        <label className="radioBox text-xl">
                          {" "}
                          {t("Female")}
                          <input {...gender} type="radio" name="gender" value="female" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={handleSignup}
                    className="bg-mainColor flex p-4 px-8 my-4 rounded-xl items-end  text-white font-bold text-2xl "
                  >
                    {t("Continue")}
                  </button>
                </>
              ) : otp ? (
                <div className="otp w-full mt-8 flex-wrap">
                  <label htmlFor="" className="text-xl w-full my-4 flex capitalize">
                    {t("enter the code that was sent to")}
                  </label>
                  <p className="mb-6 text-2xl font-bold">
                    {localStorage.i18nextLng == "ha" ? "+234" : "+971"} {phoneNumber}
                  </p>
                  {/* <form className="relative gap-4 flex items-center justify-start  " id='otp'>
                    <input type="tel" onChange={(event) => setnumber1(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                    <input type="tel" onChange={(event) => setnumber2(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                    <input type="tel" onChange={(event) => setnumber3(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                    <input type="tel" onChange={(event) => setnumber4(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                    <input type="tel" onChange={(event) => setnumber5(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                  </form> */}
                  <OtpInput
                    value={otpNumber}
                    onChange={(e) => {
                      setOtpNumber(e);
                      console.log(e);
                    }}
                    numInputs={6}
                    className={`block justify-center rounded-lg border-2 border-mainColor text-center mx-1 w-20 h-20 text-3xl`}
                    focusStyle={{ display: "block !important" }}
                    isInputNum={true}
                  />
                  <div className="flex justify-between mt-6">
                    <button onClick={handleSend} className="my-2 font-bold text-2xl text-mainColor">
                      {" "}
                      {t("Resend Code")}{" "}
                    </button>
                    <button
                      onClick={handleVer}
                      disabled={isLoading}
                      className={`${
                        isLoading && "cursor-not-allowed"
                      } bg-mainColor flex p-4 px-8 my-4 rounded-xl items-end  text-white font-bold text-2xl `}
                    >
                      {isLoading ? <span className="loader"></span> : `${t("Continue")}`}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="form-group">
                  <label htmlFor="" className="text-2xl my-4 flex capitalize">
                    {t("your mobile number")}
                  </label>
                  <div className=" relative overflow-hidden phoneNumber flex items-center justify-center  border-2 rounded-2xl pl-4 border-mainColor">
                    <div className="flex items-center justify-center text-2xl px-6 py-4">
                      <img
                        src={localStorage.i18nextLng == "ha" ? hsFlag : uae}
                        alt=""
                        className="w-8 mx-2"
                      />
                      <span>{localStorage.i18nextLng == "ha" ? "+234" : "+971"}</span>
                    </div>
                    <input
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      placeholder={t("Mobile Number")}
                      autoComplete="true"
                      id="mobile"
                      type="tel"
                      className="block w-full px-4 mt-2 text-gray-700 bg-white rtl:border-r-2 ltr:border-l-2 border-mainColor text-2xl  "
                    />
                    <button
                      onClick={handleSend}
                      className="px-6 absolute ltr:-right-4 rtl:-left-4  top-0 bg-mainColor z-50 h-full text-white font-bold text-2xl "
                    >
                      {t("Send OTP")}{" "}
                    </button>
                  </div>
                  <button
                    onClick={handleSend}
                    className="my-2 font-bold text-2xl text-mainColor px-4"
                  >
                    {t("Resend Code")}{" "}
                  </button>
                </div>
              )}
            </>
          ) : null
          // <div className="row flex items-center justify-between gap-4 w-full flex-wrap">
          //   <div className="w-full flex items-center justify-between flex-wrap xl:flex-nowrap xl:gap-4">
          //     <div className="form-group w-full  md:w-1/2 xl:w-4/12 my-4 px-2">
          //       <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="CardNumber">
          //         {" "}
          //         {t("Card Number")}{" "}
          //       </label>
          //       {/* <input type="date" name="" id="today" className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  " /> */}
          //       <input
          //         onChange={(e) => setCardNumber(e.target.value)}
          //         value={cardNumber}
          //         placeholder={t("Card Number")}
          //         type="text"
          //         name=""
          //         id="CardNumber"
          //         className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  "
          //       />
          //     </div>
          //     <div className="form-group w-full  md:w-1/2 xl:w-4/12 my-4 px-2">
          //       <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="CVV">
          //         {t("CVV")}{" "}
          //       </label>
          //       <input
          //         onChange={(e) => setCvv(e.target.value)}
          //         value={cvv}
          //         placeholder={t("CVV")}
          //         maxLength="3"
          //         type="text"
          //         name=""
          //         id="CVV"
          //         className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  "
          //       />
          //     </div>
          //     <div className="form-group w-full md:w-1/2 xl:w-4/12 my-4 px-2">
          //       <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="Date">
          //         {t("Expiry Date")}{" "}
          //       </label>
          //       <div className="flex gap-4">
          //         <input
          //           onChange={(e) => setExpMonth(e.target.value)}
          //           maxLength="2"
          //           value={expMonth}
          //           type="text"
          //           placeholder="MM"
          //           name=""
          //           id="Date"
          //           className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  "
          //         />
          //         <input
          //           onChange={(e) => setExpYear(e.target.value)}
          //           maxLength="4"
          //           value={expYear}
          //           type="text"
          //           placeholder="YYYY"
          //           name=""
          //           id="Date"
          //           className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  "
          //         />
          //       </div>
          //     </div>
          //     <div className="form-group w-full  md:w-1/2 xl:w-4/12 my-4 px-2">
          //       <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="Date">
          //         {t("Name")}{" "}
          //       </label>
          //       <input
          //         maxLength="15"
          //         onChange={(e) => setNameCustomer(e.target.value)}
          //         value={nameCustomer}
          //         type="text"
          //         placeholder={t("Name")}
          //         name=""
          //         id="Date"
          //         className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  "
          //       />
          //     </div>
          //   </div>
          //   <div className="flex gap-4 w-full items-center justify-center">
          //     <button
          //       onClick={handleBooking}
          //       disabled={isLoading}
          //       id="BookingButton"
          //       className={`${
          //         isLoading && "cursor-not-allowed"
          //       } OutlineBtn flex items-center justify-center p-4 rounded-lg text-3xl relative  font-medium py-6 bg-mainColor text-white border-2 w-full`}
          //     >
          //       {isLoading ? <span className="loader"></span> : `${t("submit order")} `}
          //     </button>
          //     <button
          //       onClick={handleBookingLater}
          //       disabled={isLoading}
          //       className={`${
          //         isLoading && "cursor-not-allowed"
          //       } OutlineBtn  flex items-center justify-center p-4 rounded-lg text-3xl relative  font-medium py-6 bg-mainColor text-white border-2 w-full`}
          //     >
          //       {isLoading ? <span className="loader"></span> : `${t("Pay later")}`}
          //     </button>
          //   </div>
          // </div>
        }
      </>
    </div>
  );
};
const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};
export default Phone;
