import React from 'react'

const SiteSettings = () => {
    return (
        <div className="w-full md:w-2/3">
            <div className="row flex flex-wrap">
                Site Settings
            </div>
        </div>
    )
}

export default SiteSettings