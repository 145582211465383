import { Link, useParams } from "react-router-dom";
import { configs, url } from "../../Commens/Commens";
import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "../UI/Loader/Loader";
import { t } from "i18next";

const OurServices = () => {
  const { id } = useParams();
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [service, setServices] = useState({});

  useEffect(() => {
    axios
      .get(url + `categories?filter=home`, configs)
      .then((response) => {
        if (response.status === 404) {
          setIsNotFound(true);
          return;
        }
        if (response.status === 400) {
          setIsNotValid(true);
          return;
        }
        const data = response.data.data;
        setServices(data.slice(0, 16));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  if (isNotFound) {
    return <p>NotFound</p>;
  }
  if (isNotValid) {
    return <p className="error">Sorry! The reservation ID is invalid.</p>;
  }
  if (isLoading) {
    return (
      <div className="Loading mt-72">
        {" "}
        <Loader />{" "}
      </div>
    );
  }

  return (
    <div className="OurServices mt-48">
      <div className="container mx-auto">
        <div className="row flex items-center justify-center flex-wrap">
          <div className="address text-center relative overflow-hidden w-full md:w-1/2 ">
            <h3 className="text-4xl xl:text-[3.5rem] mb-24  text-subColor font-bold">
              {t("Our Services")}
            </h3>
            <p className="text-2xl  text-gray  mb-6">
              {t(
                "We are a full-service cleaning and janitorial service firm that specializes in commercial and residential cleaning. We offer a wide range of services to meet all your cleaning needs, from basic housekeeping, to deep cleaning and sanitization. Our team of experienced professionals is committed to providing a high quality service that meets your individual or corporate needs."
              )}
            </p>
          </div>
          <div className="w-full row flex items-center justify-center flex-wrap">
            {service.map((item, index) => (
              <div
                className={`relative p-2 h-[216px] w-1/2 md:w-2/6 xl:w-1/6 flex`}
                key={index}
              >
                <Link
                  to={
                    localStorage.token
                      ? `/ServiceDetails/${item.id}`
                      : `${window.location.pathname}?login=true`
                  }
                  className="serviceCard transition-all transition-900 flex items-center justify-center flex-col border-2 border-mainColor w-full h-full rounded-xl p-4 text-center "
                >
                  <div className="h-[85%]">
                    <img
                      src={item.icon}
                      alt=""
                      className="h-full md:h-[140px] mx-auto"
                    />
                  </div>
                  <h3 className="text-xl font-semibold md:text-3xl md:font-medium text-subColor">
                    {item.name}
                  </h3>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
