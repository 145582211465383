import React from 'react';
import ReactDOM from 'react-dom/client';
import i18n from "i18next";
import {
  initReactI18next
} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import './index.css';
import App from './App';
import '../src/Style/index.scss';
console.log = console.warn = console.error = () => {};
console.error('Something bad happened.');
window.onbeforeunload = () => {
  localStorage.removeItem('bookingObj');
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLangs: ['ar', 'en'],
    fallbackLng: "en",
    detection: {
      order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage']
    },
    backend: {
      loadPath: '/assets/local/{{lng}}/translation.json',
    },
  });


export default i18n;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( < App / > );