import { t } from 'i18next'
import Vetted from '../../img/Vetted.png'
import Exceptional from '../../img/Exceptional.png'
import Proven from '../../img/Proven.png'
import stars from '../../img/starss.png'
import services from '../../img/services.png'
import star from '../../img/star.png'

const WhyDangiCleaners = () => {
     return (
          <div id="BestServices" className='mt-24 xl:mt-72'>
               <div className="container mx-auto">
                    <div className="row flex items-center justify-center flex-wrap">
                         <div className="w-full md:w-1/2 text-center">
                              <h3 className='text-4xl mb-6 font-medium text-subColor'>{t('Why Dangi Cleaners?')}</h3>
                              <p className='text-2xl l text-gray leading-loose'>{t('Our cleaning services provides bespoke services to a vast range of organisation from across the private & public sector in the UAE.')}</p>
                         </div>
                         <div className="row flex items-center justify-center flex-wrap relative overflow-hidden z-20">
                              <img src={star} alt="" className='absolute left-0 top-0 -z-10' />

                              <div className="w-full md:w-4/12 relative">
                                   <div className="text-center mb-16 p-2 lg:p-16">
                                        <img src={Proven} alt="" className='h-28 mb-8 mx-auto' />
                                        <h3 className='text-4xl mb-6 font-medium text-subColor'>{t('Proven and Reliable')}</h3>
                                        <p className='text-2xl l text-gray leading-loose'>{t('- All housekeepers are experienced in professional cleaning and services are provided on time.')}</p>
                                   </div>
                                   <div className="text-center mb-16 p-2 lg:p-16">
                                        <img src={Vetted} alt="" className='h-28 mb-8 mx-auto' />
                                        <h3 className='text-4xl mb-6 font-medium text-subColor'>{t('Vetted & Background checked?')}</h3>
                                        <p className='text-2xl l text-gray leading-loose'>{t('All housekeepers are thoroughly checked and interviewed before hiring ')}</p>
                                   </div>
                              </div>
                              <div className="w-full md:w-4/12">
                                   <div className="image p-8">
                                        <img src={services} alt="" className='w-10/12 mb-8 mx-auto' />
                                   </div>
                              </div>
                              <div className="w-full md:w-4/12 relative">
                                   <div className="text-center mb-16 p-2 lg:p-16">
                                        <img src={stars} alt="" className='h-16 mb-8 mx-auto' />
                                        <h3 className='text-4xl mb-6 font-medium text-subColor'>{t('5 Star Ratings')}</h3>
                                        <p className='text-2xl l text-gray leading-loose'>{t(' We keep track of reviews and feedback wherever we work to ensure the Dangi Clean Standards are always upheld.')}</p>
                                   </div>
                                   <div className="text-center mb-16 p-2 lg:p-16 ">
                                        <img src={Exceptional} alt="" className='h-28 mb-8 mx-auto' />
                                        <h3 className='text-4xl mb-6 font-medium text-subColor'>{t('Exceptional Service')}</h3>
                                        <p className='text-2xl l text-gray leading-loose'>{t('Meticulous and exceptional services that give you the sparkles you deserve')}</p>
                                   </div>
                              </div>
                         </div>

                    </div>
               </div>
          </div>
     )
}

export default WhyDangiCleaners