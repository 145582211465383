import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { configs, getErrorInCard, url } from "../../Commens/Commens";
import Loader from "../UI/Loader/Loader";
import {
  getBookingObj,
  setBookingObj,
  updateCostCard,
  showLoading,
} from "../../Commens/Commens";
import { t } from "i18next";
import { parse } from "postcss";

const Details = (props) => {
  const { id } = useParams();
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [service, setServices] = useState({});
  const [errorLength, setErrorLength] = useState();
  const [allServices, setAllServices] = useState();

  useEffect(() => {
    const sendGetRequest = async () => {
      try {
        const resp = await axios.get(url + `categories/${id}`, configs);
        if (resp.status === 404) {
          setIsNotFound(true);
          return;
        }
        if (resp.status === 400) {
          setIsNotValid(true);
          console.log(resp);
          return;
        }
        if (resp.status === 200) {
          // console.log(resp.data.data);
          setServices(resp.data.data);
          setIsLoading(false);
        }
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    sendGetRequest();
  }, [id]);

  useEffect(() => {
    console.log();
    document.getElementById("NextButton").setAttribute("disabled", "disabled");
    if (errorLength) {
      document
        .getElementById("NextButton")
        .setAttribute("disabled", "disabled");
      console.log(errorLength);
    }
    if (!errorLength) {
      document.getElementById("NextButton").removeAttribute("disabled");
      console.log(errorLength);
    }
  }, [errorLength]);

  useEffect(() => {
    document.getElementById("NextButton").setAttribute("disabled", "disabled");
  }, []);

  if (isNotFound) {
    return <p>NotFound</p>;
  }
  if (isNotValid) {
    return <p className="error">Sorry! The reservation ID is invalid.</p>;
  }
  if (isLoading) {
    return (
      <div className="Loading pt-28 flex items-center justify-center">
        {" "}
        <Loader />{" "}
      </div>
    );
  }

  function buildServiceObj() {
    // console.log(service.id)
    const serv = [];
    for (var j = 0; j < service.services.length; j++) {
      var one = service.services[j];
      var id_Serv = one.id;
      var quantity = parseInt(
        document.getElementById("counter_" + one.id).innerHTML
      );
      if (quantity > 0) {
        serv.push({ id: id_Serv, value: quantity });
      }
    }
    const old = getBookingObj();
    old.services = serv;
    if (old.services.length < 1) {
      document
        .getElementById("NextButton")
        .setAttribute("disabled", "disabled");
    }
    console.log();
    const data = old;
    const configsPost = configs;
    configsPost.data = data;
    if (serv.length > 0) {
      document.getElementById("NextButton").removeAttribute("disabled");
    }

    showLoading(true);
    axios
      .post(url + `calculate_price`, data, configsPost)
      .then((response) => {
        if (response.status === 404) {
          getErrorInCard(response);
          return;
        }
        if (response.status === 400) {
          getErrorInCard(response);
          return;
        }
        if (response.status === 200) {
          const rep = response.data.data;
          updateCostCard(rep);
          showLoading(false);
          if (response.status == 200) {
            setErrorLength("");
          }
        }
      })
      .catch((error) => {
        const rep = error;
        getErrorInCard(rep);
        console.log(rep);
        setErrorLength(rep.response.data.error);
        showLoading(false);
      });
    setBookingObj(old);
  }

  const increment = (id, step) => {
    const v = document.getElementById("counter_" + id).innerHTML;
    let n = parseInt(v) + step;
    if (n === 1 && step === 1) {
      n = 2;
    } else if (step === -1 && n === 1) {
      n = 0;
    }
    //setCount(step)
    if (n >= 0 && n < 10) {
      document.getElementById("counter_" + id).innerHTML = n;
      // setCount(n)
      buildServiceObj();
    }
  };

  return (
    <div className="w-full">
      <h3 className="font-bold mb-8 text-3xl">{service.name}</h3>

      {service.services.map((item, index) => (
        <div
          className="row flex item-center justify-center flex-wrap"
          key={index}
        >
          <div
            className="w-full flex flex-wrap mb-2 rounded-xl border-2 border-mainColor mt-6 p-4 relative"
            id={item.id}
          >
            <div className="w-3/12 ServiceDetails relative overflow-hidden r px-4 ">
              {/* <h1 className="text-2xl font-bold text-gray-800  mb-4">{item.name}</h1> */}
              <img
                src={item.media_url}
                alt=""
                className="h-auto lg:h-52 w-auto max-w-full rounded-xl object-cover"
              />
            </div>
            <div className="w-9/12 flex flex-wrap">
              <div className="ServiceDetails w-8/12 pr-8">
                <h1 className="text-2xl font-bold text-gray-800">
                  {item.name}
                </h1>
                <p className="mt-2 text-2xl text-gray-600 leading-10">
                  {item.description}
                </p>
              </div>
              <div className="ServiceOptions w-4/12 text-left md:text-right flex items-start md:items-end flex-col  md:h-full justify-around">
                <h1 className="text-2xl text-gray-800 mb-4 md:mb-10 text-subColor">
                  {t("AED")} {item.new_price} {t("per Hour")}
                </h1>
                <div className="flex items-end justify-end">
                  <button
                    className="w-8 h-8 rounded-full bg-mainColor text-3xl text-white"
                    onClick={() => {
                      increment(item.id, -1);
                    }}
                  >
                    -
                  </button>
                  <h3
                    className="text-3xl w-8 mx-4 text-center"
                    id={`counter_` + item.id}
                  >
                    {" "}
                    0{" "}
                  </h3>
                  <button
                    className="w-8 h-8 rounded-full bg-mainColor text-3xl text-white"
                    onClick={() => {
                      increment(item.id, 1);
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Details;
